import React from 'react'

function Menu() {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M17 4.08203H3V6.08203H17V4.08203ZM3 9.08203H17V11.082H3V9.08203ZM3 14.082H17V16.082H3V14.082Z" fill="white" />
		</svg>
	)
}

export default Menu