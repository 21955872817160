import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer/Footer'
import HeaderBlock from '../components/HeaderBlock/HeaderBlock'
import CountMethodics from '../components/CountMethodics/CountMethodics'
import Rating from '../components/RatingTable/Rating'
import BarChart from '../components/BarChart/BarChart'
import ComplaintsBanner from '../components/ComplaintsBanner/ComplaintsBanner'
import InstrumentsAndServices from '../components/InstrumentsAndServices/InstrumentsAndServices'

export default function MainPage() {

	// const [searchValue, setSearchValue] = useState('');
	// const [searchTrigger, setSearchTrigger] = useState(false);
	// const [searchType, setSearchType] = useState(false);
	// const [ratingSearchWrapClass, setRatingSearchWrapClass] = useState('rating-search__wrap');
	// const [loadCount, setLoadCount] = useState(0);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<div className='main-page'>
			<HeaderBlock
				// searchValue={searchValue}
				// setSearchValue={setSearchValue}
				// ratingSearchWrapClass={ratingSearchWrapClass}
				// setRatingSearchWrapClass={setRatingSearchWrapClass}
				// loadCount={loadCount}
				// setLoadCount={setLoadCount}
				// searchType={searchType}
				// setSearchType={setSearchType}
				// setSearchTrigger={setSearchTrigger}
			/>
			{/* <ComplaintsBanner /> */}
			{/* <CountMethodics /> */}
			{/* <BarChart /> */}
			<Rating
				// searchValue={searchValue}
				// setSearchValue={setSearchValue}
				// searchTrigger={searchTrigger}
				// setSearchTrigger={setSearchTrigger}
			/>
			<InstrumentsAndServices />
			{/* <RatingSearch
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				ratingSearchWrapClass={ratingSearchWrapClass}
				setRatingSearchWrapClass={setRatingSearchWrapClass}
				loadCount={loadCount}
				setLoadCount={setLoadCount}
				setSearchType={setSearchType}
			/> */}
			<Footer hasForm />
		</div>
	)
}
