import React from 'react'
import TopNav from '../TopNav/TopNav'
import Footer from '../Footer/Footer'
import FormField from '../FormField/FormField'
import { useState, useRef, useEffect } from 'react'
import Dash from '../../icons/Dash';
import { registrationForTesting } from '../../services/forms'
import './styles.css'

function TestingRegistrationContent() {

	const emailRef = useRef(null);
	const [emailValue, setEmailValue] = useState('');
	const [emailError, setEmailError] = useState('');

	const [sent, setSent] = useState(false);

	const registration = async function () {
		let email = emailValue;

		try {
			const res = await registrationForTesting(email);

			if (res !== undefined) {
				emailRef.current.value = '';
				setEmailValue('');
				setSent(true);
				localStorage.testingFormIsSent = true;
			}
		} catch (error) {
			console.log(error.response.data);
			setEmailError(error.response.data.email[0])
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<div className='testing-content'>
			<div className='testing-content__info'>
				<div className='testing-content__left-block'>
					<div className='testing-content__center-block left'>
						<div className='testing-content__block-wrap'>
							<p className='testing-content__header'>
								Фиксировать нарушения в моменте
							</p>
							<div className='testing-content__advantages'>
								<p className='testing-content__text'>Первое мобильное приложение для защиты прав клиентов ТК. Фиксируйте нарушения прямо со своего мобильного телефона – и подавайте претензии и жалобы.</p>
								{/* <div className='testing-content__text with-dash'>
									<span>Претензии на качество услуг</span>
									<div className='testing-content__dash'>
										<Dash />
									</div>
								</div>
								<div className='testing-content__text with-dash'>
									<span>Жалобы на нарушения условий труда</span>
									<div className='testing-content__dash'>
										<Dash />
									</div>
								</div>
								<div className='testing-content__text with-dash'>
									<span>Обращения в гос.органы</span>
									<div className='testing-content__dash'>
										<Dash />
									</div>
								</div> */}
								{/* <p className='testing-content__text'>Планируем начать тестирование в ноябре 2023</p> */}
							</div>
						</div>
					</div>
				</div>
				<div className='testing-content__right-block'>
					<div className='testing-content__center-block right'>
						<div className='testing-content__block-wrap'>
							{!sent && !localStorage.testingFormIsSent ?
								(<>
									<div className='testing-content__field-wrap'>
										<FormField
											caption='Электронная почта'
											inputRef={emailRef}
											setInputValue={setEmailValue}
											placeholder_text='Электронная почта'
											error={emailError}
											setError={setEmailError}
										/>
										<p className='testing-content__field-bottom-caption'>Отправим приглашение</p>
									</div>
									<button className='testing-content__sign-up' onClick={() => registration()}>
										Записаться
									</button>
								</>) :
								sent && localStorage.testingFormIsSent ?
									(
										<div className='testing-content__mail-sent'>
											<p className='testing-content__mail-sent-header'>
												Вы записались
											</p>
											<p className='testing-content__mail-sent-text'>
												Ожидайте приглашение
											</p>
										</div>
									)
									:
									(
										<div className='testing-content__mail-sent'>
											<p className='testing-content__mail-sent-text'>
												Вы уже записались на тестирование. С вами свяжется наш менеджер
											</p>
										</div>
									)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TestingRegistrationContent