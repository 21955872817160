import React from 'react'
import './styles.css'
import InfoSlider from '../InfoSlider/InfoSlider'

function InstrumentsAndServices() {
	return (
		<div className='services'>
			<div className='container'>
				<div className='services__text-info'>
					<p className='services__header'>Инструменты и сервисы</p>
					<p className='services__text'>Эффективные инструменты для борьбы за права клиентов ТК и сервис для повышения качества работы логистических операторов.</p>
				</div>
				<div className='services__slider-wrap'>
					<InfoSlider />
				</div>
			</div>
		</div>
	)
}

export default InstrumentsAndServices