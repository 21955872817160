import { useState, useMemo } from "react"
import style from "./research.module.css"
import Upload from '../../icons/Upload';
import TopNav from '../TopNav/TopNav'

export default function ResearchHeader() {

    return (

        <div className={style['research-header']}>
            <div className="container">
                <TopNav></TopNav>
                <div className={style['research-header__content-wrap']}>
                    <h1>Исследования</h1>
                    <div className={ style['research-header__content'] }>
                        <div className={style['research-header__caption']}>
                            Исследования ОПИ основаны на собственных программных продуктах и уникальной методике анализа больших данных по административной  и судебной практике, что позволяет выявлять недобросовестные практики и тенденции, свойственные различным отраслям.
                        </div>

                        <div className={style['research-header__sm-caption']}>Наличие у ОПИ активистов во всех регионах страны также позволяет осуществлять масштабные рейды по выявлению и пресечению нелегальных рыночных практик</div>
                    </div>

                </div>
            </div>
        </div>
    )
}
