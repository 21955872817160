import './style.css'


import CloseCross from '../../icons/CloseCross'

export default function SearchSelect({ value, list = [], onChange, onClear, onSelected, placeholder }) {
    return (
        <div className='search__container'>
            <input
                type="text"
                className='search-input'
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e.target.value)}
            />
            {value !== '' && (
                <button
                    type="reset"
                    className="search-input-btn"
                    onClick={onClear}
                >
                    <CloseCross />
                </button>
            )}
            {list.length ? <ul className='search__list'>
                {list.map((item, id) => <li onClick={() => onSelected(item)} key={id}>{item.name}</li>)}
            </ul> : <ul className='search__list'><li>Ничего не найдено</li></ul>}
        </div>
    )
}