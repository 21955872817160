import React from 'react'
import Footer from '../components/Footer/Footer';
import TopNav from '../components/TopNav/TopNav';
import './styles.css';
import Header from '../components/SetupMobileApp/Header';
import InstructionSetupApp from '../components/SetupMobileApp/Instruction';

function SetupMobileApp() {
	return (
		<div className='carrier-api'>
			<TopNav classes={[ 'sm-bg-color-darken-blue', 'bg-color-body']}/>
			<Header />
			<InstructionSetupApp />
			<Footer />
		</div>
	)
}

export default SetupMobileApp