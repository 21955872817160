import TopNav from "../TopNav/TopNav";
import React from "react";
import style from "./article.module.css";

export default function ArticleHeader() {
    return (
        <div className={style['article-header-block']}>
            <TopNav/>
            <div className={'container ' + style['article-header-block__container']}>
                <h1>
                    Логистические платформы — маркетплейсы как инструмент для организации грузоперевозок
                </h1>
            </div>
        </div>
    )
}
