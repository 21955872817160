import { useState, useMemo } from "react"
import style from "./download.module.css"

export default function Header(props) {
	const {
		children,
        className,
        link,
        target = "_blank"
	} = props

    return (
        <div className={[ style['download'], className ].join(' ')}>
            <a className={[ style['download__link'] ]} href={link} target={target}>
                <div className={ style['download__content'] }>
                    {children}
                </div>
            </a>
        </div>
    )
}