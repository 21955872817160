import React, { useEffect } from "react";
import ArticleHeader from "../components/Article/ArticleHeader";
import ArticleBlock from "../components/Article/ArticleBlock";
import Footer from "../components/Footer/Footer";
import {Helmet} from "react-helmet";

export default function ArticlePage() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<>
			<Helmet>
				<title>Логистические платформы как маркетплейсы</title>
				<meta name="description"
					  content="Новое исследование оценивает развитие и эффективность более 100 сервисов в 25 логистических платформах в России."/>
				<meta property="og:title" content="Логистические платформы как маркетплейсы"/>
				<meta property="og:description" content="Новое исследование оценивает развитие и эффективность более 100 сервисов в 25 логистических платформах в России."/>
			</Helmet>
			<div className='main-page'>
				<ArticleHeader/>
				<ArticleBlock/>
				<Footer hasForm/>
			</div>
		</>
	)
}
