import { useState, useMemo } from "react"
import './style.css'

import DropArrow from '../../icons/DropArrow'

export default function Select({ values, value, onChange }) {
    const [isOpen, setOpen] = useState(false)
    const handleChange = (key) => {
        setOpen(false)
        onChange(key)
    }

    const options = useMemo(() => {
        return values.filter((el) => el.name !== value?.name)
    }, [values, value]);

    return (
        <div className="select__container">
            <span className="select__value" onClick={() => setOpen(!isOpen)}>
                {value ? value.name : ""}
                <span className={isOpen ? 'select_open' : ''} >
                    <DropArrow />
                </span>
            </span>
            {isOpen && values && <ul className="select__list">
                {options.map((el) => (
                    <li key={el.name} onClick={() => handleChange(el)} className="select__element">
                        {el.name}
                    </li>))}
            </ul>}
        </div>
    )
}