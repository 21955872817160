import React from 'react';
import './styles2.css';
import '../RatingTable/style.css';

function AboutCountMethodics() {
	return (
		<div className='about-rating'>
			<p className='about-rating__header'>В основе рейтинга – информация из государственных информационных систем и открытых источников.</p>
			<p className='about-rating__text'>Компании* условно делятся на три группы:</p>
			<div className='about-rating__parameters row'>
				<div className='about-rating__parameter col-lg-4'>
					<p className='about-rating__parameter-name'>Надежные (AA, AB, BA)</p>
					<p className='about-rating__parameter-description'>Обеспечивают определенный уровень качества и безопасности при отсутствующих или минимальных рисках.</p>
				</div>
				<div className='about-rating__parameter col-lg-4'>
					<p className='about-rating__parameter-name'>Сомнительные (AC, BB, CA)</p>
					<p className='about-rating__parameter-description'>Уровень качества и безопасности ниже, чем у компаний первой группы, при этом число и (или) характер рисков достаточно весомое.</p>
				</div>
				<div className='about-rating__parameter col-lg-4'>
					<p className='about-rating__parameter-name'>Рисковые (BC, CB, CC)</p>
					<p className='about-rating__parameter-description'>Низкий уровень качества и безопасности. Значительные риски.</p>
				</div>
				<div className='about-rating__parameter first col-lg-6'>
					<p className='about-rating__parameter-description'>Первая буква в оценке компании указывает на качественные характеристики транспортной компании: наличие собственного парка транспортных средств, позволяющего обеспечивать бесперебойную доставку грузов, география работы, обеспеченность кадровыми и финансовыми ресурсами, характер поисковых запросов и посещаемость сайта.</p>
				</div>
				<div className='about-rating__parameter second col-lg-6'>
					<p className='about-rating__parameter-description'>Вторая буква указывает на юридическую оценку деятельности компании: наличие налоговых и прочих рисков: дата регистрации компании, достоверность сведений (о руководителе, учредителях, адресе), заблокированные счета, задолженность по налогам, соответствие уровня зарплаты средней по региону, признаки налоговой оптимизации и др.</p>
				</div>
			</div>
			<div className='about-rating__table-wrap'>
				<div className='rating-table__table methodics'>
					<div className='rating-table__table-head methodics'>
						<div className='rating-table__th rating-table__th--1 methodics'>
							Рейтинг
						</div>
						<div className='rating-table__th rating-table__th--2 methodics'>
							Описание
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							AA
						</div>
						<div className='rating-table__col-2'>
			
								Имеющиеся у компании ресурсы позволяют эффективно выполнять принятые на себя обязательства.<br />
								Компания характеризуется максимальным уровнем надежности, устойчивости и безопасности.
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							AB
						</div>
						<div className='rating-table__col-2'>
			
								Имеющиеся у компании ресурсы позволяют эффективно выполнять принятые на себя обязательства. <br />
								Однако уровень финансовой надежности, устойчивости и безопасности ниже, чем у компании в рейтинговой категории AA и характеризуется определенными рисками.
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							AC
						</div>
						<div className='rating-table__col-2'>
			
								Имеющиеся у компании ресурсы позволяют выполнять принятые на себя обязательства.<br />
								Однако компанию характеризует низкий уровень финансовой надежности, устойчивости и безопасности и сопутствующие риски.
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							ВА
						</div>
						<div className='rating-table__col-2'>
			
								Имеющиеся у компании ресурсы соответствуют среднему уровню и позволяют выполнять принятые на себя обязательства с определенными рисками для клиента.<br />
								Компания характеризуется высоким уровнем надежности, устойчивости и безопасности.
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							ВB
						</div>
						<div className='rating-table__col-2'>
			
								Имеющиеся у компании ресурсы соответствуют среднему уровню и позволяют выполнять принятые на себя обязательства с определенными рисками для клиента.<br />
								Уровень финансовой надежности, устойчивости и безопасности ниже, чем у компании в рейтинговой категории BA и характеризуется определенными рисками.
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							ВC
						</div>
						<div className='rating-table__col-2'>
			
								Имеющиеся у компании ресурсы соответствуют среднему уровню и позволяют выполнять принятые на себя обязательства с определенными рисками для клиента.<br />
								Компанию характеризует низкий уровень финансовой надежности, устойчивости и безопасности и сопутствующие риски.
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							СA
						</div>
						<div className='rating-table__col-2'>
			
								У компании отсутствуют необходимые ресурсы для выполнения обязательств по перевозке.<br />
								Однако компания характеризуется определенным уровнем юридической стабильности.
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							СB
						</div>
						<div className='rating-table__col-2'>
			
								У компании отсутствуют необходимые ресурсы для выполнения обязательств по перевозке.<br />
								Уровень финансовой надежности, устойчивости и безопасности ниже, чем у компании в рейтинговой категории CA и характеризуется определенными рисками
						</div>
					</div>
					<div className='rating-table__table-tr methodics'>
						<div className='rating-table__td rating-table__td--1 methodics'>
							СC
						</div>
						<div className='rating-table__col-2'>
			
								У компании отсутствуют необходимые ресурсы для выполнения обязательств по перевозке.<br />
								Компанию характеризует низкий уровень финансовой надежности, устойчивости и безопасности и сопутствующие риски.
						</div>
					</div>
				</div>
			</div>
			<div className='about-rating__footnote'>
				<p className='about-rating__footnote-star'>*</p>
				<p className='about-rating__footnote-text'>Если компания осуществляет свою деятельность в составе группы юридических лиц и (или) имеет признаки дробления, присвоение первой буквы рейтинга (качественные характеристики) осуществлялось на основании совокупной оценки показателей группы юридических лиц.<br />
					Если компания осуществляет свою деятельность в составе группы юридических лиц и (или) имеет признаки дробления, присвоение второй буквы рейтинга (уровень рисков) осуществлялось на основании оценки юридического лица с наихудшими показателями.</p>
			</div>
		</div>
	)
}

export default AboutCountMethodics