import './style.css'
import BarChartItem from './BarChartItem';
import { Tooltip } from 'react-tooltip';
import { useEffect, useState } from 'react';

export default function BarChartDiadgam({ data }) {

	const [qualitativeShowMore, setQualitativeShowMore] = useState(false);
	const [doubtfulShowMore, setDoubtfulShowMore] = useState(false);
	const [riskyShowMore, setRiskyShowMore] = useState(false);


	let qualitative = [
		{ type: 'aa', text: 'Имеющиеся у компании ресурсы позволяют эффективно выполнять принятые на себя обязательства. Компания характеризуется максимальным уровнем надежности, устойчивости и безопасности.' },
		{ type: 'ab', text: 'Имеющиеся у компании ресурсы позволяют эффективно выполнять принятые на себя обязательства. Однако уровень финансовой надежности, устойчивости и безопасности ниже, чем у компании в рейтинговой категории AA и характеризуется определенными рисками.' },
		{ type: 'ba', text: 'Имеющиеся у компании ресурсы соответствуют среднему уровню и позволяют выполнять принятые на себя обязательства с определенными рисками для клиента. Компания характеризуется высоким уровнем надежности, устойчивости и безопасности.' },
	]

	let doubtful = [
		{ type: 'ac', text: 'Имеющиеся у компании ресурсы позволяют выполнять принятые на себя обязательства. Однако компанию характеризует низкий уровень финансовой надежности, устойчивости и безопасности и сопутствующие риски.' },
		{ type: 'bb', text: 'Имеющиеся у компании ресурсы соответствуют среднему уровню и позволяют выполнять принятые на себя обязательства с определенными рисками для клиента. Уровень финансовой надежности, устойчивости и безопасности ниже, чем у компании в рейтинговой категории BA и характеризуется определенными рисками.' },
		{ type: 'ca', text: 'У компании отсутствуют необходимые ресурсы для выполнения обязательств по перевозке. Однако компания характеризуется определенным уровнем юридической стабильности.' },
	]

	let risky = [
		{ type: 'bc', text: 'Имеющиеся у компании ресурсы соответствуют среднему уровню и позволяют выполнять принятые на себя обязательства с определенными рисками для клиента. Компанию характеризует низкий уровень финансовой надежности, устойчивости и безопасности и сопутствущие риски.' },
		{ type: 'cb', text: 'У компании отсутствуют необходимые ресурсы для выполнения обязательств по перевозке. Уровень финансовой надежности, устойчивости и безопасности ниже, чем у компании в рейтинговой категории CA и характеризуется определенными рисками.' },
		{ type: 'cc', text: 'У компании отсутствуют необходимые ресурсы для выполнения обязательств по перевозке. Компанию характеризует низкий уровень финансовой надежности, устойчивости и безопасности и сопутствующие риски.' },
	]

	useEffect(() => {
		if (qualitativeShowMore) {
			setDoubtfulShowMore(false);
			setRiskyShowMore(false);
		}

	}, [qualitativeShowMore])

	useEffect(() => {
		if (doubtfulShowMore) {
			setQualitativeShowMore(false);
			setRiskyShowMore(false);
		}

	}, [doubtfulShowMore])

	useEffect(() => {
		if (riskyShowMore) {
			setDoubtfulShowMore(false);
			setQualitativeShowMore(false);
		}

	}, [riskyShowMore])

	return (
		<div className='bc-diagram-wrap'>
			<div className='bc-diagram'>
				<BarChartItem
					id="bar-chart-item-a"
					category='Надежные'
					data={data?.a}
					descriptions={qualitative}
					animation
				/>
				<BarChartItem
					category='Сомнительные'
					data={data?.b}
					descriptions={doubtful}
					animation
				/>
				<BarChartItem
					category='Рисковые'
					data={data?.c}
					descriptions={risky}
					animation
				/>
				<div className='bc-bg'>
					<div className="bc-bg__line" />
					<div className="bc-bg__line" />
					<div className="bc-bg__line" />
					<div className="bc-bg__line" />
					<div className="bc-bg__line bc-bg__line--down" />
				</div>
			</div>
			<div className='bc-footer'>
				<div className="bc-footer__item">
					<div className="bc-footer__category">
						Качественные
					</div>
					<div className="bc-footer__text">
						Максимальная надежность компании. эффективно выполняет обязательства. Высокая безопасность перевозок
					</div>
					<div className='bc-footer__descriptions'>
						<button onClick={() => setQualitativeShowMore(!qualitativeShowMore)} className='bc-footer__show-more'>
							{qualitativeShowMore ? 'Скрыть' : 'Подробнее'}
						</button>
						{qualitativeShowMore ?
							<div className='bc-footer__full-descriptions'>
								{qualitative.map((item) =>
									<div className='bc-footer__category-description bc-footer__text'>
										<span className='bc-footer__category-letters'>{item.type}</span> - {item.text}
									</div>
								)}
							</div> : null
						}
					</div>
				</div>
				<div className="bc-footer__item">
					<div className="bc-footer__category">
						Сомнительные
					</div>
					<div className="bc-footer__text">
						Средняя надежность компании. Есть чувствительность к рыночным изменениям. Умеренно низкая безопасность перевозок
					</div>
					<div className='bc-footer__descriptions'>
						<button onClick={() => setDoubtfulShowMore(!doubtfulShowMore)} className='bc-footer__show-more'>
							{doubtfulShowMore ? 'Скрыть' : 'Подробнее'}
						</button>
						{doubtfulShowMore ?
							<div className='bc-footer__full-descriptions'>
								{doubtful.map((item) =>
									<div className='bc-footer__category-description bc-footer__text'>
										<span className='bc-footer__category-letters'>{item.type}</span> - {item.text}
									</div>
								)}
							</div> : null
						}
					</div>
				</div>
				<div className="bc-footer__item">
					<div className="bc-footer__category">
						Рисковые
					</div>
					<div className="bc-footer__text">
						Низкая надежность компании. Вероятно неисполнения обязательств. Есть нарушения в безопасности перевозок
					</div>
					<div className='bc-footer__descriptions'>
						<button onClick={() => setRiskyShowMore(!riskyShowMore)} className='bc-footer__show-more'>
							{riskyShowMore ? 'Скрыть' : 'Подробнее'}
						</button>
						{riskyShowMore ?
							<div className='bc-footer__full-descriptions'>
								{risky.map((item) =>
									<div className='bc-footer__category-description bc-footer__text'>
										<span className='bc-footer__category-letters'>{item.type}</span> - {item.text}
									</div>
								)}
							</div> : null
						}
					</div>
				</div>
			</div>
		</div>
	)
}
