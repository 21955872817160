import React, { memo, useRef } from 'react';
import './style.css';
import { debounce } from 'lodash'
import { getRatingTableData, getCompenieById, getSearchData, getFile } from '../../services/ratingData';
import { useMediaPredicate } from "react-media-hook";
import { useState, useCallback, useEffect } from 'react';
import Upload from '../../icons/Upload';

import SearchSelect from '../SearchSelect/SearchSelect';


function RatingTable({ activeRating }) {
	const prevActiveRating = useRef();
	const [showMore, setShowMore] = useState(false);
	const [storageList, setStorageList] = useState([]);
	const [notFound, setNotFound] = useState(false);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('')
	const [count, setCount] = useState(0);

	const [list, setList] = useState([])
	const filterSearch = useCallback(debounce(async (value, group) => {
		setSearch(value)
		setList([])
		try {
			const res = await getSearchData({ search: value, group });
			setList([...res.data.results.sub_companies, ...res.data.results.companies])
		} catch (e) {
			console.log(e)
		}
	}, 400), [])

	useEffect(() => {
		filterSearch(search, activeRating.name)
	}, [search, activeRating.name])

	const clear = () => {
		setActive(null)
		setStorageList([]);
		setPage(1);
		setShowMore(false);
	}

	const [subcompany, setSub] = useState(null)

	const clearHandler = () => {
		clear()
		setSearch('');
		getTableData(1, '', activeRating.name);
		setSub(null)
		setSubcompanies([])
	}

	const isCompanie = (item) => {
		if (item.brand) {
			return false
		}

		return true
	}

	const selectedFirstItem = async (e) => {
		e?.preventDefault()
		clear()
		const item = list[0]
		setSearch(item.name);
		if (isCompanie(item)) {
			setStorageList([item]);
		} else {
			setSub(item)

			const data = await getSubcompanies(item.brand, item)
			setStorageList([data]);
		}
	}

	const selectedItem = async (item) => {
		clear()
		setSearch(item.name)
		if (isCompanie(item)) {
			setStorageList([item]);
		} else {
			setSub(item)

			const data = await getSubcompanies(item.brand, item)
			setStorageList([data]);
		}
	}

	const getMore = () => {
		setPage(page + 1)
		getTableData(page + 1, '', activeRating.name)
	}



	useEffect(() => {
		clearHandler();
	}, [activeRating.name])


	const showMoreChange = (next) => {
		if (next !== null) {
			setShowMore(true);
		} else {
			setShowMore(false);
		}
	}

	const countChange = (count) => {
		if (count === 0) {
			setNotFound(true);
		}
		if (count !== 0) {
			setCount(count);
		}
	}
	const getTableData = useCallback(debounce(async (page, search = '', group) => {
		try {
			const res = await getRatingTableData({ page, search, group });

			showMoreChange(res.data.next);
			countChange(res.data.count);

			setStorageList((list) => [...list, ...res.data.results]);
		} catch (e) {
			console.log('Ошибка по АПИ данным проекта: ', e)
		} finally {

		}
	}, 400), [])

	const [active, setActive] = useState(null)

	const [subcompanies, setSubcompanies] = useState([])
	const getSubcompanies = async (id, sub) => {
		try {
			const { data } = await getCompenieById({ id, group: activeRating.name })
			setSubcompanies(data.subcompany.filter(x => x.inn !== sub?.inn && x.inn !== subcompany?.inn))

			return data
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		setSubcompanies([])

		if (active) {
			getSubcompanies(active)
		}
	}, [active])


	return (
		<div className='rating-table__tab-block'>
			<div className='rating-table__wrap'>
				<div className='rating-table__header'>
					Комплесный подход к оценке WhiteIndex от ОПИ является полностью прозрачным и основан на открытых данных, которые каждый может найти в интернете.
				</div>
				<div className='rating-table__text-wrap'>
					<div className='rating-table__text'>
						Первая буква оценки указывает на качественные инфраструктурные характеристики компаний. Вторая – на результаты юридического анализа компаний, с учетом налоговых и прочих рисков. Полностью ознакомиться с методикой расчета вы можете в соседней вкладке.
					</div>
					<div className="rating-table__under-diagram-rigth">
						Для удобства, мы собрали данные про каждую компанию в едином PDF-файле, который можно <a href={activeRating.file} target='_blank'>скачать</a> здесь. В перспективе индекс и на данном портале станет интерактивным.
					</div>
				</div>
				<div className="rating-table__search">
					<div className="rating-table__search-wrap">
						<form className='rating-table__form' onSubmit={selectedFirstItem}>
							<SearchSelect placeholder='ИНН или название компании'
								value={search}
								onChange={setSearch}
								onClear={clearHandler}
								onSelected={selectedItem}
								list={list}
							/>
						</form>
						<a className='rating-table__btn' href={activeRating.file} target='_blank'>
							<span>Скачать рейтинг в pdf</span>
							<Upload />
						</a>
					</div>
				</div>
				<div className='rating-table__table'>
					<div className='rating-table__table-head'>
						<div className='rating-table__th rating-table__th--1' />
						<div className='rating-table__th rating-table__th--2'>
							{/* {mobWidth ? 'Компания / ИНН' : 'Компания'} */}
							Транспортная компания
						</div>
						<div className='rating-table__th rating-table__th--3'>
							{/* ИНН */}
						</div>
						<div className='rating-table__th rating-table__th--4'>
							Рейтинг
						</div>
						{/* <div className='rating-table__th rating-table__td-btn'>

						</div> */}
					</div>
					{notFound ?
						<div className="rating-table__not-found">
							<div className="rating-table__not-found-title">
								Компании с таким ИНН или названием не найдены
							</div>
							<div className="rating-table__not-found-caption">
								Убедитесь, что данные введены корректно
							</div>
						</div>
						:
						storageList?.map((item, index) => {
							return (
								<div
									key={index}
									className='rating-table__table-tr'
								>
									<div className='rating-table__td rating-table__td--1'>
										<span>{index + 1}</span>
									</div>
									<div className='rating-table__td rating-table__td--2'>
										<div>{item.name}</div>
										<div className='rating-table__subitems'>
											{subcompany && <div>
												{subcompany.name} {subcompany.inn}
											</div>}
											{!!item.count_subcompanies && active == item.id
												?
												<div className='rating-table__dropdown rating-table__dropdown_offset' onClick={() => setActive(null)}>
													Скрыть
												</div>
												:
												<div className='rating-table__dropdown' onClick={() => setActive(item.id)}>
													{subcompany ? item.count_subcompanies > 1 && `Ещё: ${item.count_subcompanies - 1}` : `Юридических лиц: ${item.count_subcompanies}`}
												</div>
											}
											{!!subcompanies.length && active == item.id && subcompanies.map(item => <div key={item.inn}>
												{item.name}, {item.inn}
											</div>)}
										</div>
									</div>
									<div className='rating-table__td rating-table__td--3'>
										{/* <div>{item.inn}</div> */}
									</div>
									<div className='rating-table__td rating-table__td--4'>
										<span className='rating-table__rating-middle'>{item.rating}</span>
									</div>
								</div>
							)
						})}
				</div>
			</div>
			<div className='rating-table__bottom-buttons'>
				{showMore ? <ShowMore page={page} setPage={getMore} /> : null}
			</div>
		</div>
	)
}

export default RatingTable

const ShowMore = ({
	page,
	setPage
}) => {
	return (
		<button className='show-more' onClick={() => setPage(page + 1)}>
			Показать еще
		</button>
	)
}