import React, { useState, useEffect, useCallback } from 'react'
import ChevronRight from '../../icons/ChevronRight'
import RatingModal from './RatingModal';
import Tabs from '../Tabs/Tabs'
import RatingTable from './RatingTable';
import './style.css'
import BarChart from '../BarChart/BarChart';
import AboutCountMethodics from '../CountMethodics/AboutCountMethodics';
import Select from '../Select/Select'
import { getTransportGroups } from '../../services/ratingData'

export default function Rating(props) {
	const [transportGroups, setTransportGroups] = useState([]);
	const {
		searchValue,
		setSearchValue,
		searchTrigger,
		setSearchTrigger,
	} = props


	// const mobWidth = useMediaPredicate('(max-width:991.9px)');
	// const descWidth = useMediaPredicate('(min-width:992px)');


	// const [modalActive, setModalActive] = useState(false);

	const tabsList = [
		{
			text: 'Актуальный срез рынка',
			name: 'market-analysis'
		},
		{
			text: 'Рейтинг перевозчиков',
			name: 'rating'
		},
		{
			text: 'Методика расчета',
			name: 'about-rating'
		},
	];
	const [activeTab, setActiveTab] = useState(tabsList[0]);

	const [activeRating, setActiveRating] = useState();


	const getTransportGroupsData = async () => {
		try {
			const { data: res } = await getTransportGroups();
			if (res.length) {
				setActiveRating(res[0]);
			}
			setTransportGroups(res);
			setActiveTab(tabsList[0]);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getTransportGroupsData();
	}, [])

	return (
		<div className='rating-table' id="rating-table">
			<div className='container'>
				<div className='section-title'>рейтинг</div>
				<h2>
					<span className='rating-table__title'>Транспортные компании</span>
					<Select values={transportGroups} onChange={(value) => {
						setActiveRating(value);
					}} value={activeRating} />
				</h2>
				<div className='rating-table__tabs'>
					<Tabs
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						tabsList={tabsList}
					/>
				</div>
				<div className='rating-table__tab-wrap'>
					{activeTab.name == 'rating' ?
						<RatingTable activeRating={activeRating} />
						: null
					}
					{activeTab.name == 'market-analysis' ?
						<BarChart group={activeRating?.name} description={activeRating?.description}/>
						: null
					}
					{activeTab.name == 'about-rating' ?
						<AboutCountMethodics />
						: null
					}
				</div>
			</div>
			{/* {modalData && modalActive ? <RatingModal
				data={modalData}
				setModalActive={setModalActive}
			/> : null} */}
		</div>
	)
}