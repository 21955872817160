import React from 'react'

function AndroidIcon() {
    return (
        <svg width="26" height="28" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_175_1440)">
                <path d="M16.2481 28.215C17.1078 28.215 17.8576 27.4152 17.8576 26.4982V22.4886H18.9323C19.5773 22.4886 20.0071 22.0299 20.0071 21.3422V9.89021H7.12061V21.3423C7.12061 22.0301 7.55049 22.4887 8.19533 22.4887H9.27013V26.4982C9.27013 27.4153 10.0197 28.2151 10.8797 28.2151C11.7394 28.2151 12.4891 27.4153 12.4891 26.4982V22.4887H14.6386V26.4982C14.6385 27.4152 15.3883 28.215 16.2481 28.215Z" fill="white" />
                <path d="M22.6911 21.3423C23.5509 21.3423 24.3005 20.5426 24.3005 19.6254V11.6063C24.3005 10.6941 23.5509 9.89021 22.6911 9.89021C21.8312 9.89021 21.0815 10.6941 21.0815 11.6063V19.6254C21.0815 20.5425 21.8311 21.3423 22.6911 21.3423Z" fill="white" />
                <path d="M4.43702 21.3422C5.29688 21.3422 6.04657 20.5426 6.04657 19.6254V11.6063C6.04657 10.694 5.29696 9.89017 4.43702 9.89017C3.57724 9.89017 2.82764 10.694 2.82764 11.6063V19.6254C2.82764 20.5425 3.57724 21.3422 4.43702 21.3422Z" fill="white" />
                <path d="M18.7173 0.959682C18.5024 0.729036 18.1826 0.729036 17.9677 0.959682L16.5262 2.49211L16.4597 2.56295C15.605 2.10642 14.6467 1.87843 13.5796 1.87623C13.5743 1.87623 13.5692 1.87605 13.564 1.87605H13.5638C13.5584 1.87605 13.5534 1.87623 13.548 1.87623C12.4809 1.87843 11.5226 2.10642 10.668 2.56295L10.6013 2.49211L9.15996 0.959682C8.94493 0.729036 8.62522 0.729036 8.41036 0.959682C8.19533 1.18905 8.19533 1.52943 8.41036 1.75862L9.80471 3.2462C9.35543 3.56617 8.94802 3.95772 8.59372 4.40556C7.74553 5.47798 7.20452 6.87367 7.13013 8.38587C7.12944 8.40161 7.12799 8.41717 7.1273 8.43291C7.12275 8.53588 7.12061 8.6394 7.12061 8.74328H20.0071C20.0071 8.6394 20.0047 8.53588 20.0004 8.43291C19.9997 8.41717 19.9982 8.40161 19.9974 8.38587C19.9231 6.87367 19.382 5.47789 18.5338 4.40566C18.1796 3.95782 17.7721 3.56627 17.3228 3.24629L18.7173 1.75871C18.9323 1.52943 18.9323 1.18905 18.7173 0.959682ZM10.8776 6.74169C10.433 6.74169 10.0725 6.35718 10.0725 5.88289C10.0725 5.4086 10.433 5.0241 10.8776 5.0241C11.3223 5.0241 11.6827 5.4086 11.6827 5.88289C11.6827 6.35718 11.3223 6.74169 10.8776 6.74169ZM16.25 6.74169C15.8054 6.74169 15.4449 6.35718 15.4449 5.88289C15.4449 5.4086 15.8054 5.0241 16.25 5.0241C16.6947 5.0241 17.0552 5.4086 17.0552 5.88289C17.0552 6.35718 16.6947 6.74169 16.25 6.74169Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_175_1440">
                    <rect width="25.7143" height="27.4286" fill="white" transform="translate(0.714355 0.785721)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AndroidIcon
