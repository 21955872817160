import style from './article.module.css';

import Img1 from '../../assets/articleImg/img1.png'
import Img2 from '../../assets/articleImg/img2.png'
import Img3 from '../../assets/articleImg/img3.png'
import Img4 from '../../assets/articleImg/img4.png'
import Img5 from '../../assets/articleImg/img5.png'
import Img6 from '../../assets/articleImg/img6.png'
import {useCallback, useState} from "react";

const companiesList = [
    {
        "name": "Atlas Chain",
        "href": "https://atlaschain.ru/"
    },
    {
        "name": "Atrucks",
        "href": "https://www.atrucks.su/"
    },
    {
        "name": "Axelot",
        "href": "https://www.axelot.ru/"
    },
    {
        "name": "Cargomart",
        "href": "https://cargomart.ru/"
    },
    {
        "name": "Cargo.Run",
        "href": "https://cargorun.ru/"
    },
    {
        "name": "Crafter",
        "href": "https://crafter.online/"
    },
    {
        "name": "Deliver (ex ICanDeliver)",
        "href": "https://www.deliver.ru/"
    },
    {
        "name": "Groozgo",
        "href": "https://groozgo.ru/"
    },
    {
        "name": "Loginet",
        "href": "https://loginet.ru/"
    },
    {
        "name": "LogistPro",
        "href": "https://logistpro.su/"
    },
    {
        "name": "Maxoptra",
        "href": "https://maxoptra.ru/"
    },
    {
        "name": "Oboz",
        "href": "https://oboz.com/"
    },
    {
        "name": "Pooling",
        "href": "https://pooling.me/"
    },
    {
        "name": "Smartseeds",
        "href": "https://smartseeds.ru/"
    },
    {
        "name": "Trucker",
        "href": "https://landing.trucker.group/"
    },
    {
        "name": "Traffic",
        "href": "https://traffic.online/"
    },
    {
        "name": "Vezubr",
        "href": "https://vezubr.ru/"
    },
    {
        "name": "Xplanet",
        "href": "https://xplanet.ru/"
    },
    {
        "name": "АвтоТрансИнфо (ati.su)",
        "href": "https://ati.su/"
    },
    {
        "name": "ВезётВсем",
        "href": "https://www.vezetvsem.ru/"
    },
    {
        "name": "Дорожная сеть",
        "href": "https://dornet.ru/"
    },
    {
        "name": "Мой Груз",
        "href": "https://www.moigruz.ru/"
    },
    {
        "name": "Монополия Онлайн",
        "href": "https://monopoly.ru/"
    },
    {
        "name": "Умная логистика",
        "href": "https://ul.su/"
    },
    {
        "name": "Яндекс Магистрали",
        "href": "https://dostavka.yandex.ru/magistral/"
    }
];

export default function ArticleContent() {
    return (
        <div className={style['article-content-block']}>
            <article id="about-research">
                По оценкам ОПИ, рынок логистических платформ достиг 67,4 млрд рублей в 2022 году. Прогнозируемый темп роста CAGR до 2026 составляет не менее 32%. Все больше перевозок в стране оформляется именно через логистические платформы: грузовладельцы из различных отраслей в режиме онлайн ищут перевозчиков и перевозят свои грузы в точки назначения.

                <br/>
                <br/>


                <div className={style['article-content-block_counters']}>
                    <div>
                        <span className={style['article-content-block_counter']}>
                            <span className={style['article-content-block_counter__big']}>67,4</span>
                            <span>млрд руб.</span>
                        </span>
                        <span>рынок логистических платформ в РФ (2022)</span>
                    </div>

                    <div>
                        <span className={style['article-content-block_counter']}>
                            <span className={style['article-content-block_counter__big']}>32</span>
                            <span>%</span>
                        </span>
                        <span>прогнозируемый среднегодовой темп роста до 2026 года</span>
                    </div>
                </div>

                <br/>
                <br/>
                Логистические платформы помогают пользователям перевести «в цифру» процесс закупки грузоперевозок, сократить ручные операции и оптимизировать процесс логистики. Тем не менее, пользователи сталкиваются с разными рода проблемами, с которыми к нам обращаются: перегрузы ТС, мошеннические схемы, несвоевременная и низкая оплата труда, нарушения прав потребителей и др.
                <br/>
                <br/>
                ОПИ провело независимое исследование рынка логистических платформ, чтобы понять, как пользователи, грузовладельцы и перевозчики закрывают свои логистические задачи, как развиты платформы, какие сервисы предоставляют пользователям и насколько они эффективны.
            </article>

            <article id="methodology">
                <br/>
                <br/>
                <header>
                    <h5 className={style['article-content-block-section']}>
                        Методология
                    </h5>
                    <h4 className={style['article-content-block-title']}>
                        Как проводили исследование?
                    </h4>
                </header>
                <br/>
                Ключевая цель данного исследования заключается в необходимости формирования понимания рынка
                логистических платформ в РФ и оценки уровня его развития.
                <br/>
                <br/>
                Исследование также было призвано оценить текущую насыщенность рынка логистических платформ
                предоставляемыми ими сервисами для организации безопасных и эффективных логистических процессов
                компаний. Для этого были проанализированы 25 логистических платформ рынка РФ.

                <br/>
                <br/>
                <br/>

                <header>
                    <h5 className={style['article-content-block-subtitle']}>Как оценивали</h5>
                </header>
                Для оценки рынка логистических платформ были проанализированы 25 логистических платформ рынка РФ:
                <br/>
                <br/>
                <div className={style['chip-list']}>
                    {companiesList.map(company => (
                        <a rel="noopener noreferrer" key={company.href} className={style['chip']} target="_blank" href={company.href}>
                            <span>{company.name}</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.6691 6.27614L4.93141 12.0139L3.9886 11.0711L9.72633 5.33333H4.66915V4H12.0025V11.3333H10.6691V6.27614Z"
                                    fill="#2D5979"/>
                            </svg>
                        </a>
                    ))}
                </div>
                <br/>
                <br/>
                В исследовании использовались качественные и количественные методы, сбор и анализ данных проводился в
                период с сентября по ноябрь 2023 года по открытым источникам (сайты компаний, базы знаний, публикации в
                СМИ), личным кабинетам на логистических платформах от лица грузовладельца и демо-презентаций и интервью
                с коммерческими службами платформ под легендой планирования покупки на сервисе.
                <br/>
                <br/>
                Для оценки наполненности сервисами проанализировали более 100 сервисов, использовали метод балльной
                оценки платформ на основе кодирования качественных данных о функционале платформ (функционал
                есть/нет/частично/нет данных).

                <br/>
                <br/>

                <div className={style['article-content-block_counters']}>
                    <div>
                        <span className={style['article-content-block_counter']}>
                            <span className={style['article-content-block_counter__big']}>25</span>
                            <span></span>
                        </span>
                        <span>логистических платформ рынка РФ</span>
                    </div>

                    <div>
                        <span className={style['article-content-block_counter']}>
                            <span className={style['article-content-block_counter__big']}>100+</span>
                            <span></span>
                        </span>
                        <span>различных ИТ-сервисов на платформах</span>
                    </div>
                </div>
            </article>

            <article id="market-review">
                <br/>
                <br/>
                <header>
                    <h5 className={style['article-content-block-section']}>
                        Обзор рынка
                    </h5>
                    <h4 className={style['article-content-block-title']}>
                        Как сейчас работает рынок логистических платформ?
                    </h4>
                </header>
                <br/>
                Существующие российские логистические платформы можно разделить по степени их участия в логистических
                процессах:
                <br/>
                <br/>
                <img className={style['article-img']} src={Img1} alt="Существующие российские логистические платформы"/>

                <br/>
                <br/>
                <br/>

                <header>
                    <h5 className={style['article-content-block-subtitle']}>Цифровые логистические платформы</h5>
                </header>
                <br/>
                Это цифровые инструменты, которые объединяют грузовладельцев и коммерческих перевозчиков, предоставляя
                им ИТ-сервисы для взаимодействия друг с другом в режиме онлайн, при этом не являются участниками
                процессов организации перевозки и не несут ответственность за нее. Также есть платформы, которые
                сфокусированы на одном направлении деятельности, например, «Дорожная сеть», Maxoptra.

                <br/>
                <br/>
                <br/>

                <header>
                    <h5 className={style['article-content-block-subtitle']}>Платформы экспедиторы</h5>
                </header>
                <br/>
                Это цифровые платформы, которые не только обеспечивают участников логистических процессов ИТ-сервисами
                для организации совместной деятельности, но также являются полноценной стороной договора по исполнению
                перевозки с взиманием комиссии за оказанные услуги, тем самым они несут ответственность за перевозку.
                <br/>
                <br/>
                Рынок российских логистических платформ активно развивается, несмотря на барьеры, которые сейчас
                фиксируются: нехватка ИТ-кадров, недостаточная автоматизация, необходимость донастройки относительно
                базовых функций — дополнительные расходы, собственные внутренние платформы крупного бизнеса.
                <br/>
                <br/>
                Тем не менее, большинство компаний показывают высокие темпы роста и маржинальность. Ниже представлено
                распределение всех участников рынка.
                <br/>
                <br/>
                <img className={style['article-img']} src={Img2} alt="Существующие российские логистические платформы"/>
            </article>

            <article id="users">
                <br/>
                <br/>
                <header>
                    <h5 className={style['article-content-block-section']}>
                        Пользователи платформ
                    </h5>
                    <h4 className={style['article-content-block-title']}>
                        Кто основные пользователи платформ?
                    </h4>
                </header>

                <br/>
                <br/>

                <header>
                    <h5 className={style['article-content-block-subtitle']}>Грузовладельцы</h5>
                </header>
                Это компании или индивидуальные предприниматели, у которых есть потребность найти транспортное средство
                и перевезти свой груз клиенту. Услуги востребованы среди грузовладельцев в ритейле, промышленности,
                FMCG, электронной коммерции; также в роли грузовладельцев часто выступают экспедиторы, которые
                предоставляют свои услуги первоначальным грузовладельцам для организации их логистической функции.
                <br/>
                <br/>
                На всех этапах пути грузовладельца его потребности закрываются различными сервисами: большинство
                платформ позволяют грузовладельцу работать с разовыми перевозками и помогают определить цену и маршрут,
                согласовать перевозку, оформить все необходимые документы и мониторить статус исполнения перевозки.
                Сервисы мониторинга перевозки и коммуникации довольно распространены на платформах, при этом часто
                процессы коммуникации выходят за рамки платформ и происходят напрямую.
                <br/>
                <br/>
                <img className={style['article-img']} src={Img3} alt=""/>

                <br/>
                <br/>

                <br/>
                <header>
                    <h5 className={style['article-content-block-subtitle']}>Перевозчики</h5>
                </header>
                Перевозчик может быть как индивидуальным предпринимателем, так и крупной логистической компанией. Их
                основная задача — организовывать и обеспечивать перевозку грузов от отправителя к получателю.
                <br/>
                <br/>
                Перевозчики вынуждены использовать несколько платформ одновременно, так как все клиенты используют
                разные платформы для автоматизации закупок грузоперевозок.
                <br/>
                <br/>
                Перевозчики обычно выбирают заявки только по подходящим маршрутам и собственной оценке стоимости
                перевозки. С помощью платформ перевозчики согласовывают перевозки и оформляют документы, а также
                используют платформу для коммуникации с грузовладельцами.
                <br/>
                <br/>
                <img className={style['article-img']} src={Img4} alt=""/>
            </article>

            <article id="functions">
                <br/>
                <br/>
                <header>
                    <h5 className={style['article-content-block-section']}>
                        Сервисы и функции
                    </h5>
                    <h4 className={style['article-content-block-title']}>
                        Какие функции есть на платформах и достаточно ли их?
                    </h4>
                </header>
                <br />
                Российские логистические платформы предлагают более 30 различных сервисов для своих пользователей. Из
                всех выявленных в ходе исследования сервисов были выделены 12 блоков для анализа наполняемости платформ
                в зависимости от направленности каждого сервиса.

                <br/>
                <br/>

                <div className={style['article-content-block_counters']}>
                    <div>
                        <span className={style['article-content-block_counter']}>
                            <span className={style['article-content-block_counter__big']}>30+</span>
                            <span></span>
                        </span>
                        <span>базовых сервисов представлены на платформах</span>
                    </div>

                    <div>
                        <span className={style['article-content-block_counter']}>
                            <span className={style['article-content-block_counter__big']}>12</span>
                            <span></span>
                        </span>
                        <span>основных направлений</span>
                    </div>
                </div>
                <br/>
                <img className={style['article-img']} src={Img5} alt=""/>

                <br/>
                <br/>

                <br/>
                <header>
                    <h5 className={style['article-content-block-subtitle']}>Наличие сервисов на платформах</h5>
                </header>
                Наличие сервисов было проанализировано на каждой из исследуемых платформ, после чего удалось
                выявить, насколько сервисы представлены на платформах.
                <br/>
                <br/>

                <Services/>
            </article>

            <article id="levels">
                <br/>
                <br />
                <header>
                    <h5 className={style['article-content-block-section']}>
                        Уровни развития платформ
                    </h5>
                    <h4 className={style['article-content-block-title']}>
                        В чем различия платформ и как они решают логистические задачи?
                    </h4>
                </header>
                <br/>
                Существующие российские логистические платформы можно разделить не только по степени их участия в логистических процессах между грузовладельцем и перевозчиком, но и по уровню развития платформы и широте спектра задач, которые они решают. Некоторые игроки рынка выходят за рамки поиска контрагента для реализации перевозки за счет автоматизации всего жизненного цикла заявки от планирования потребности до аналитики проведенной перевозки.
                <br/>
                <br/>
                <img className={style['article-img']} src={Img6} alt=""/>
                <br/>
                <br/>

                <h5 className={style['article-content-block-subtitle__red']}>
                    1. Доска объявлений
                </h5>
                На базовом по функционалу логистических платформ уровне – уровне поиска – логистическая платформа служит виртуальным пространством для соединения грузовладельца и перевозчика через создание заявки на перевозку и поиска исполнителя на заявку.

                <ShowMore>
                    <>
                        <br/>
                        <br/>
                        Базовый уровень предполагает наличие разрозненных сервисов по поддержке решений по выбору исполнителя: рейтинг благонадежности, индекс рыночных цен на перевозку по тому или иному направлению, сервисы по страхованию и электронному документообороту.
                        <br/>
                        <br/>
                        Среди таких платформ можно выделить АвтоТрансИнфо (ati.su) как самую популярную доску объявлений в логистике.
                        <br/>
                        <br/>
                        <b>Преимущества для грузовладельцев:</b>
                        <br/>
                        <ul>
                            <li>
                                снижение затрат на стоимость перевозки через торги;
                            </li>
                            <li>
                                поиск исполнителей из широкого числа перевозчиков;
                            </li>
                            <li>
                                поиск попутчиков, с которыми можно отправлять груз;
                            </li>
                            <li>
                                поиск новых партнеров.
                            </li>
                        </ul>
                        <br/>
                        <b>Преимущества для перевозчиков:</b>
                        <br/>
                        <ul>
                            <li>
                                возможность быстро начать бизнес в логистике и получать заказы от грузовладельцев;
                            </li>
                            <li>
                                поиск спотовых заявок (разовых) с учетом своих критериев для разовых потребностей, например, закрыть обратные рейсы и не ехать с пустым кузовом, найти загрузку срочно (если сорвалась заявка);
                            </li>
                            <li>
                                предоставление актуальных рыночных цен на грузоперевозки.
                            </li>
                        </ul>
                        <br/>
                        <b>Недостатки таких платформ:</b>
                        <br/>
                        <ul>
                            <li>
                                много недобросовестных участников, как со стороны грузовладельцев, так и со стороны перевозчиков;
                            </li>
                            <li>
                                нет гарантий о безопасности сделки;
                            </li>
                            <li>
                                нет возможности управлять на всем жизненном цикле заявки на перевозку, нужно использовать дополнительные сервисы.
                            </li>
                        </ul>
                    </>
                </ShowMore>

                <br/>

                <h5 className={style['article-content-block-subtitle__red']}>
                    2. Платформы управления заявкой
                </h5>
                На этом уровне платформа способна закрывать базовые потребности по обслуживанию жизненного цикла заявки, при этом часть процессов остается непокрытыми платформой и требует взаимодействия контрагентов за ее рамками.

                <ShowMore>
                    <>
                        <br/>
                        <br/>
                        <b>Преимущества для грузовладельцев:</b>
                        <br/>
                        <ul>
                            <li>
                                управление риском незакрытия заявки на всех этапах;
                            </li>
                            <li>
                                возможность отследить статус по заявке на всем жизненном цикле;
                            </li>
                            <li>
                                возможность создать внутренний контур и работать по заявкам только со своими
                                перевозчиками;
                            </li>
                            <li>
                                использование дополнительных сервисов на всех этапах жизненного цикла заявки: индекс
                                цены, ЭДО, коммуникация внутри платформы по заявке.
                            </li>
                        </ul>
                        <br/>
                        <b>Преимущества для перевозчиков:</b>
                        <br/>
                        <ul>
                            <li>
                                проверка добросовестности грузовладельцев и их платежеспособности;
                            </li>
                            <li>
                                возможность выбора заявок с учетом своих критериев эффективности и доступных ТС;
                            </li>
                            <li>
                                управление кассовыми разрывами и снижением затрат на топливо/стоянки;
                            </li>
                            <li>
                                электронный документооборот.
                            </li>
                        </ul>
                        <br/>
                        <b>Недостатки таких платформ:</b>
                        <br/>
                        <ul>
                            <li>
                                сложно найти нового грузовладельца или стать его партнером, т.к. большинство открывают
                                заявки только на своих перевозчиков;
                            </li>
                            <li>
                                сложность с поиском перевозчиков на непопулярных направлениях;
                            </li>
                            <li>
                                нет возможности управлять всеми заявками из одного окна;
                            </li>
                            <li>
                                не покрывают полный цикл управления закупками грузоперевозок;
                            </li>
                            <li>
                                одни и те же заявки публикуются на разных платформах.
                            </li>
                        </ul>
                    </>
                </ShowMore>

                <br/>

                <h5 className={style['article-content-block-subtitle__red']}>
                    3. Платформы управления логистикой
                </h5>
                Также на рынке представлены логистические платформы, которые за счет расширения количества предлагаемых сервисов покрывают все этапы пути (CJM) грузовладельца и перевозчика по закупке грузоперевозок. При таком подходе для закупок контрагенты могут взаимодействовать только в рамках одной платформы, начиная с поиска исполнителей и заканчивая аналитикой и управлением лояльностью контрагентов.

                <ShowMore>
                    <>
                        <br/>
                        <br/>
                        Платформы помогают пользователям реализовывать сложные бизнес-правила и правильно принимать решения. Для этого логист должен обладать соответствующими знаниями и опытом, а также уметь пользоваться сервисами и извлекать нужную информацию из них.
                        <br/>
                        Среди платформ, находящихся на таком уровне, можно выделить несколько: «Умная Логистика», Axelot, «Монополию Онлайн» и другие.
                        <br/>
                        <br/>
                        <b>Преимущества для грузовладельцев: </b>
                        <br/>
                        <ul>
                            <li>
                                управление всем циклом и потоком заявок, как для собственного парка, так и для внешнего;
                            </li>
                            <li>
                                аналитика за период по заявкам, контроль работы логистической функции;
                            </li>
                            <li>
                                управление риском незакрытия заявок через различные инструменты; (расширение круга
                                исполнителей, корректировка ставки за перевозку и др).
                            </li>
                            <li>
                                выстраивание партнерских отношений с контрагентами.
                            </li>
                        </ul>
                        <br/>
                        <b>Преимущества для перевозчиков:</b>
                        <br/>
                        <ul>
                            <li>
                                возможность получить большой контракт на плановые перевозки;
                            </li>
                            <li>
                                управление и анализ перевозок;
                            </li>
                            <li>
                                электронный документооборот и оплата через платформы;
                            </li>
                            <li>
                                возможность масштабировать бизнес и привлекать партнеров-перевозчиков для закрытия своих
                                перевозок.
                            </li>
                        </ul>
                        <br/>
                        <b>Недостатки таких платформ:</b>
                        <br/>
                        <ul>
                            <li>
                                не смотря на автоматизацию всех процессов по пути грузовладельца и перевозчика для
                                оптимальных и эффективных логистических решений нужно участие профессионального логиста;
                            </li>
                        </ul>
                    </>
                </ShowMore>

                <br />

                <h5 className={style['article-content-block-subtitle__red']}>
                    4. Экспертные платформы
                </h5>
                Описанный выше уровень постепенно трансформируется в новый, еще более сложный, на котором логистическая платформа уже не просто предоставляет набор сервисов и инструментов для оптимизации логистических процессов, но также сама выступает в роли логистического эксперта.

                <ShowMore>
                    <>
                        <br />
                        <br />
                        На уровне оптимизации и экспертизы логистическая платформа потенциально может стать системой интеллектуального управления логистической функцией на всем жизненном цикле управления ТЛУ и помогать принимать верные решения вне зависимости от наличия экспертизы у логиста: именно платформа за счет автоматизации сбора данных и широких аналитических возможностей станет тем самым цифровым экспертом, которая дополняет интеллектуальные ресурсы компании.
                        <br />
                        <br />
                        Уже сейчас можно отметить платформы, которые стремятся занять эту нишу и постепенно дополняет свой функционал экспертными сервисами: Axelot, Traffic, Xplanet.
                        <br />
                        <br />
                        <b>Потенциал:</b>
                        <br />
                        <ul>
                            <li>
                                возможность распределять заявки на перевозки по квотам, учитывать потенциал и мощность парка перевозчика, ценовую политику и другие факторы;
                            </li>
                            <li>
                                взаимосвязь сервисов между этапами закупки грузоперевозок и предложение оптимальных логистических решений;
                            </li>
                            <li>
                                проявление должной осмотрительности по участникам платформ и своевременное информирование о неблагонадежности участников, как за счет внутренних сервисов платформ (закрытие перевозок, прозрачная рейтинговая система), так и за счет интеграции с внешними данными.
                            </li>
                        </ul>
                    </>
                </ShowMore>
            </article>

            <article id="prospects">
                <br />
                <header>
                    <h5 className={style['article-content-block-section']}>
                        Перспективы
                    </h5>
                    <h4 className={style['article-content-block-title']}>
                        Какие перспективы развития и риски?
                    </h4>
                </header>
                <br/>
                По оценкам ОПИ, будущее цифровых логистических платформ выглядит вполне ясным. Взрывной рост агрегаторов в различных сферах экономики является лучшим доказательством.
                <br/>
                <br/>
                Онлайн-покупка товаров, заказ такси, доставка продуктов и готовой еды, выбор билетов — вот лишь малая часть сфер, где агрегаторы уже играют решающую роль. Нет сомнений, что и в сфере перевозок они будут расширять свою нишу и привлекать все новых участников, вытесняя или замещая традиционные логистические сервисы и услуги.
                <br/>
                <br/>
                Однако наряду со всеми преимуществами, уже сейчас начинают проявляться отдельные недостатки некоторых платформенных решений в сфере логистики.
                <br />
                <br />
                Отсутствие жесткого ценза на отбор участников, заинтересованность в привлечении как можно большего числа игроков (перевозчиков, экспедиторов, грузоотправителей) и, как следствие, недостаточный уровень проверки контрагентов приводят к ряду негативных эффектов. Прежде всего, речь идет о проникновении на платформы игроков, в чьей деятельности в том или ином сочетании отмечаются:                <br/>
                <br/>
                <ul>
                    <li>
                        мошеннические практики (хищения грузов);
                    </li>
                    <li>
                        налоговые правонарушения (различные схемы сокрытия выручки и незаконной оптимизации);
                    </li>
                    <li>
                        переработки водителей;
                    </li>
                    <li>
                        нарушения в сфере системы «Платон», оформления накладных и путевых листов;
                    </li>
                    <li>
                        нарушения в сфере общественной безопасности (ДТП, опасные грузы и т.п.);
                    </li>
                    <li>
                        трафик нелегальной продукции и др.
                    </li>
                </ul>
                <br />
                Данные нарушения не являются нарушениями логистических платформ, но платформы вполне могут способствовать их сокращению.
                <br />
                <br />
                Это понимают и профильные ведомства, которые уже озвучили первые инициативы по регулированию агрегаторов, касающиеся в первую очередь, ужесточения контроля за допускаемыми на площадки игроками и предлагаемыми к перевозке грузами.
                <br />
                <br />
                В завершении можно заметить, что сейчас у цифровых логистических платформ сохраняется возможность избежать жесткого сценария регулирования. Для этого необходимо выработать стандарты добросовестного поведения, прежде всего, в отношении отбора контрагентов, и неукоснительно им следовать.
                <br />
                <br />
                Надо перестать рассматривать рынок логистики и все что на нем происходит, как вещь в себе. Логистика влияет на все сферы экономики. Качественный и прозрачный рынок логистики – это сокращение теневого товарооборота, рост поступлений в бюджет и снижение числа инцидентов. Именно через эту призму на рынок смотрит государство. И именно через эту призму платформам надо трансформировать свою деятельность.
                <br />
                <br />
            </article>
        </div>
    )
}

const serviceList = [
    'Онлайн-биржа',
    'Типы заявок',
    'ТЛУ на платформе',
    'Тип перевозки',
    'Безопасность',
    'Планирование и маршрутизация',
    'Аналитика',
    'Документы и финансы',
    'Управление и мониторинг',
    'Интеграции и инструменты',
    'Работа с претензиями',
    'Лояльность',
];

function Services() {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(serviceList[0]);

    const toggleOpen = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    return (
        <>
            <div className={style['services-dropdown'] + ' ' + (open ? style['services-dropdown__open'] : '')}>
                <div onClick={toggleOpen} className={style['services-dropdown_selected']}>
                    <span>{selected}</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"
                            fill="#A3B0B8"/>
                    </svg>
                </div>
                <ul onClick={toggleOpen}
                    className={style['services-dropdown_options'] + ' ' + (open ? style['services-dropdown_options__open'] : '')}>
                    {serviceList.map((service) => (
                        <li key={service} onClick={() => setSelected(service)}>{service}</li>
                    ))}
                </ul>
            </div>

            <div className={style['services-chips']}>
                {serviceList.map((service) => (
                    <span key={`chip_${service}`} onClick={() => setSelected(service)} className={style['services-chip'] + ' ' + (selected === service ? style['services-chip__active'] : '')}>{service}</span>
                ))}
            </div>

            <br/>

            {selected === serviceList[0] && (
                <>
                    <header>
                        <h5 className={style['article-content-block-subtitle']}>1. Онлайн-биржа (Маркетплейс)</h5>
                    </header>
                    <span style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>
                        Отдельным блоком сервисов является онлайн-биржа как базовый сервис внутри логистических платформ.
                        Практически все рассмотренные платформы имели функционал онлайн-биржи для поиска и выставления
                        груза, который соединяет грузовладельцев и перевозчиков, предоставляя им ИТ-сервисы для эффективной
                        и безопасной работы.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        { legend: 'Есть на платформах', count: 88 },
                        { legend: 'Отсутствует', count: 12 },
                    ]} />
                </>
            )}

            {selected === serviceList[1] && (
                <>
                    <header>
                        <h5 className={style['article-content-block-subtitle']}>2. Cпотовые заявки</h5>
                    </header>
                    <span style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>
                        Заявки, которые закрывают разовые потребности для конкретной перевозки.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 96},
                        {legend: 'Отсутствует', count: 4},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>3. Объемные контракты</h5>
                    </header>
                    <span style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>
                        Заявки, которые предполагают согласование гарантированных перевозок с фиксированным объемом и периодом. Объемные контракты могут заключаться с одним или несколькими исполнителями, в них предусматриваются фиксированные выполнения обязательств по договору.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 64},
                        {legend: 'Отсутствует', count: 36},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>4. Квотирование</h5>
                    </header>
                    <span style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>
                        Создание заявки на несколько исполнителей, при которых грузовладелец знает только примерный объем перевозок и может менять его в рамках распределения заказов по определенным квотам. Такие заявки отличает больший объем, чем у объемного контракта и менее жесткие условия сотрудничества. При этом объем перевозок распределяется между перевозчиками по квотам, который задает логист.
                    </span>
                    <br/>
                    <br/>
                    <span style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>
                        Исследование показало, что возможности квотирования на сегодняшний день почти не представлены на российских логистических платформах, только на платформе Traffic.
                    </span>
                    <br />
                    <br />

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 20},
                        {legend: 'Отсутствует', count: 72},
                        {legend: 'Частично есть/планируется', count: 4},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>
                </>
            )}

            {selected === serviceList[2] && (
                <>
                    <header>
                        <h5 className={style['article-content-block-subtitle']}>5. FTL</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Покупка всей машины для перевозки, одна машина перевозит груз только одного грузовладельца.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 92},
                        {legend: 'Отсутствует', count: 8},
                        // {legend: 'Частично есть/планируется', count: 4},
                        // {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>6. LTL</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Покупка места в ТС, при которой в одной машине перевозится сборный груз от разных грузовладельцев.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 40},
                        {legend: 'Отсутствует', count: 56},
                        {legend: 'Частично есть/планируется', count: 4},
                        // {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>7. Мультимодальные перевозки</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Перевозка одного груза разным видом транспорта (например, сначала авиаперевозка, затем авто).
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 20},
                        {legend: 'Отсутствует', count: 72},
                        {legend: 'Частично есть/планируется', count: 8},
                        // {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>8. Догруз</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Сервис, который позволяет скооперироваться с другим грузовладельцем и в одном ТС перевезти груз,
                        чтобы оптимизировать расходы на перевозку и сократить время на закрытие заявки.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 28},
                        {legend: 'Отсутствует', count: 64},
                        {legend: '', count: 0},
                        {legend: 'Не выявлено', count: 8},
                    ]}/>

                </>
            )}

            {selected === serviceList[3] && (
                <>
                    <header>
                        <h5 className={style['article-content-block-subtitle']}>9. Магистральная перевозка</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Перевозка между разными городами.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 96},
                        {legend: 'Отсутствует', count: 4},
                        // {legend: 'Частично есть/планируется', count: 4},
                        // {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br />
                    <br />

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>10. Внутригородская перевозка</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Перевозка в пределах одного города.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 40},
                        {legend: 'Отсутствует', count: 40},
                        {legend: 'Частично есть/планируется', count: 16},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>
                </>)}

            {selected === serviceList[4] && (
                <>
                    <span style={{ fontSize: '16px', fontWeight: '500'}}>
                        Блок "Безопасность" отражает наполненность логистических платформ сервисами проверки контрагентов на
                        благонадежность, а также наличие проверки на входе на платформу (регистрации). Подобные сервисы
                        являются одними из самых важных, поскольку они обеспечивают безопасную работу перевозчика и
                        грузовладельца.
                    </span>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>
                            11. Проверка на благонадежность контрагентов
                        </h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 72},
                        {legend: 'Отсутствует', count: 20},
                        {legend: 'Частично есть/планируется', count: 8},
                        // {legend: 'Не выявлено', count: 4},
                    ]}/>
                </>
            )}

            {selected === serviceList[5] && (
                <>
                    <span style={{ fontSize: '16px', fontWeight: '500'}}>
                        Ряд сервисов, представленный на логистических платформах, направлен на оптимизацию планирования отправки груза и поиск оптимального маршрута - группаж и маршрутизация.
                    </span>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>12. Группаж</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Сервис, который помогает группировать груз в грузоместа и распределять их по ТС в зависимости от времени, города назначения, способа и типа доставки.
                    </span>
                    <br />
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 8},
                        {legend: 'Отсутствует', count: 88},
                        {legend: 'Частично есть/планируется', count: 4},
                        // {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br />
                    <br />

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>13. Маршрутизация (Первая/последняя миля)</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Сервис, который направлен на построение маршрута по оптимальным точкам. Выделяют магистральную маршрутизацию и работу с первой и последней милей.
                    </span>
                    <br />
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 32},
                        {legend: 'Отсутствует', count: 52},
                        {legend: 'Частично есть/планируется', count: 8},
                        {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br />
                    <br />

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>14. Маршрутизация (Магистральная)</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 80},
                        {legend: 'Отсутствует', count: 20},
                        // {legend: 'Частично есть/планируется', count: 8},
                        // {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br />
                    <br />
                </>
            )}

            {selected === serviceList[6] && (
                <>
                    <span style={{fontSize: '16px', fontWeight: '500'}}>
                        Сервисы аналитики, которые являются важнейшими инструментами, помогающими принимать правильные логистические решения и оптимизировать логистические издержки, присутствуют у более чем половины логистических платформ, при этом большая их часть являются слабо адаптируемыми под конкретные нужды каждого бизнеса в связи с отсутствием возможности кастомизации сервисов.
                    </span>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>
                            15. Аналитика цен и рынка/Помощник формирования цены
                        </h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 60},
                        {legend: 'Отсутствует', count: 28},
                        {legend: 'Частично есть/планируется', count: 8},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>16. Аналитические сервисы для ГВ и перевозчиков</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 72},
                        {legend: 'Отсутствует', count: 24},
                        {legend: 'Частично есть/планируется', count: 4},
                        {legend: 'Не выявлено', count: 0},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>17. Кастомная настраиваемая аналитика</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 40},
                        {legend: 'Отсутствует', count: 52},
                        {legend: 'Частично есть/планируется', count: 4},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>18. Рейтинг перевозчика и грузовладельца (скоринг)</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 52},
                        {legend: 'Отсутствует', count: 36},
                        {legend: 'Частично есть/планируется', count: 4},
                        {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br/>
                    <br/>
                </>
            )}

            {selected === serviceList[7] && (
                <>
                    <header>
                        <h5 className={style['article-content-block-subtitle']}>19. Страхование</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Онлайн в несколько кликов: от оформления полиса до фиксации страхового случая.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 64},
                        {legend: 'Отсутствует', count: 20},
                        {legend: 'Частично есть/планируется', count: 12},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br />
                    <br />

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>
                            20. Электронный документооборот (ЭДО)
                        </h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Интеграция с сервисами ЭДО.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 80},
                        {legend: 'Отсутствует', count: 8},
                        {legend: 'Частично есть/планируется', count: 4},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br />
                    <br />

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>
                            21. Интегрированный сервис оплат на платформе
                        </h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 36},
                        {legend: 'Отсутствует', count: 48},
                        {legend: 'Частично есть/планируется', count: 4},
                        {legend: 'Не выявлено', count: 12},
                    ]}/>

                    <br />
                    <br />

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>
                            22. Финансовые инструменты для сокращения срока оплаты
                        </h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        В разных моделях (факторинг/ускоренная оплата).
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 36},
                        {legend: 'Отсутствует', count: 40},
                        {legend: 'Частично есть/планируется', count: 16},
                        {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br />
                    <br />

                </>
            )}

            {selected === serviceList[8] && (
                <>
                    <header>
                        <h5 className={style['article-content-block-subtitle']}>
                            23. Управление перевозками среди своих партнеров (TMS)
                        </h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Возможность работать со своими перевозчиками на определенных условиях.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 68},
                        {legend: 'Отсутствует', count: 24},
                        {legend: 'Частично есть/планируется', count: 8},
                        // {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>24. Мониторинг</h5>
                    </header>
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: 400}}>
                        Отслеживание транспорта и параметров датчиков в режиме реального времени.
                    </span>
                    <br/>
                    <br/>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 88},
                        {legend: 'Отсутствует', count: 12},
                        // {legend: 'Частично есть/планируется', count: 8},
                        // {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br/>
                    <br/>
                </>
            )}

            {selected === serviceList[9] && (
                <>
                    <span style={{fontSize: '16px', fontWeight: '500'}}>
                        Сервисы, которые представляют дополнительные возможности для оптимизации взаимодействия пользователя
                        с платформой – возможность доступа к платформе с помощью мобильного приложения, интеграция с
                        мессенджерами и почтой, кастомизация платформы, а также доступ к возможностям платформы с помощью
                        API.
                    </span>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>25. API</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 84},
                        {legend: 'Отсутствует', count: 12},
                        {legend: 'Частично есть/планируется', count: 4},
                        // {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>26. Интеграция с мессенджерами, почтой</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 76},
                        {legend: 'Отсутствует', count: 24},
                        // {legend: 'Частично есть/планируется', count: 4},
                        // {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>27. Мобильное приложение</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 80},
                        {legend: 'Отсутствует', count: 20},
                        // {legend: 'Частично есть/планируется', count: 4},
                        // {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>28. Кастомизация и масштабирование</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 56},
                        {legend: 'Отсутствует', count: 36},
                        {legend: 'Частично есть/планируется', count: 0},
                        {legend: 'Не выявлено', count: 8},
                    ]}/>

                </>
            )}

            {selected === serviceList[10] && (
                <>
                    <span style={{fontSize: '16px', fontWeight: '500'}}>
                        Работа с претензиями: формирование претензий по перевозке прямо на платформе.
                    </span>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>29. Работа с претензиями: фиксирование претензий в системе, статусная модель</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 52},
                        {legend: 'Отсутствует', count: 40},
                        {legend: 'Частично есть/планируется', count: 0},
                        {legend: 'Не выявлено', count: 8},
                    ]}/>

                    <br/>
                    <br/>
                </>
            )}

            {selected === serviceList[11] && (
                <>
                    <span style={{fontSize: '16px', fontWeight: '500'}}>
                        Управление взаимоотношениями между грузовладельцем и перевозчиком также нашло отражение в сервисах, представленных на логистических платформах.
                        Данный блок сервисов помогает в развитии лояльности перевозчика и включает в себя наличие топливных карт и программ лояльности – дополнительных услуг от партнеров. Можно отметить, что подобные сервисы имеют только до половины логистических платформ, вошедших в анализ.
                    </span>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>30. Топливные карты</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 32},
                        {legend: 'Отсутствует', count: 64},
                        {legend: 'Частично есть/планируется', count: 0},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br/>
                    <br/>

                    <header>
                        <h5 className={style['article-content-block-subtitle']}>31. Программы лояльности</h5>
                    </header>

                    <HorizontalBarChart data={[
                        {legend: 'Есть на платформах', count: 32},
                        {legend: 'Отсутствует', count: 64},
                        {legend: 'Частично есть/планируется', count: 0},
                        {legend: 'Не выявлено', count: 4},
                    ]}/>

                    <br/>
                    <br/>
                </>
            )}
        </>
    );
}

function ShowMore(props) {
    const {children} = props;
    const [open, setOpen] = useState(false);

    const onToggle = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    return (
        <>
            {open && children}

            <p onClick={onToggle} className={style['show-more'] + ' ' + (open ? style['show-more__open'] : '')}>
                <span>Подробнее</span>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.9999 13.8374L16.9497 8.8877L18.3639 10.3019L11.9999 16.6659L5.63599 10.3019L7.0502 8.8877L11.9999 13.8374Z"
                        fill="#A3B0B8"/>
                </svg>
            </p>
        </>
    )
}


function HorizontalBarChart(props) {
    const {data} = props;
    return (
        <div className={style['hb-block']}>
            <div className={style['hb-block-wrap']}>
                {data.map(i => (
                    <div key={i.legend}
                         style={{width: `${i.count}%`, display: i.count ? 'block' : 'none', padding: i.count < 10 ? '14px 8px 14px 4px' : '14px'}}>{i.count}%</div>
                ))}
            </div>
            <div className={style['hb-block-legend']}>
                {data.map(i => (
                    <div key={i.legend} style={{display: i.count ? 'flex' : 'none'}}>
                        <span/>
                        <span>{i.legend}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
