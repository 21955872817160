import style from "./article.module.css";
import ArticleTableOfContents from "./ArticleTableOfContents";
import ArticleContent from "./ArticleContent";

export default function ArticleBlock() {
    return (
        <div className={style['article-block']}>
            <div className={'container ' + style['article-content-block__container']}>
                <div className={style['article-block__wrap']}>
                    <ArticleContent />
                    <ArticleTableOfContents />
                </div>
            </div>
        </div>
    )
}
