import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import './styles.css'

function FormField(props) {
	const {
		caption,
		type,
		placeholder_text,
		inputRef,
		setInputValue,
		error,
		setError
	} = props

	return (
		<div className='form-field'>
			{caption ?
				<p className='form-field__caption'>
					{caption}
				</p> :
				null
			}
			{
				type !== 'textarea' ?
					<input
						className={`form-field__input${error !== '' ? ' error' : ''}`}
						type='text'
						ref={inputRef}
						placeholder={placeholder_text}
						onChange={() => {
							setInputValue(inputRef.current.value);
							setError('');
						}}
					/>
					:
					<textarea
						className={`form-field__input${error !== '' ? ' error' : ''}`}
						type='text'
						ref={inputRef}
						placeholder={placeholder_text}
						onChange={() => {
							setInputValue(inputRef.current.value);
							setError('');
						}}
					></textarea>
			}
			{error !== '' ?
				<div className='form-field__error'>
					{error}
				</div>
				: null
			}
		</div>
	)
}

export default FormField