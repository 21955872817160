import { useState, useMemo } from "react"
import style from "./download.module.css"
import AndroidIcon from "../../../icons/AndroidIcon"

export default function DownloadApk() {
    return (
        <div className={style['download-apk']}>
            <div className="d-flex align-items-center">
                <AndroidIcon />
                <div className={style['download-apk__right']}>
                    <div className={ style['download-apk__title'] }>Скачать APK</div>
                    <div className={ style['download-apk__subtitle'] }>Android</div>
                </div>
            </div>
        </div>
    )
}