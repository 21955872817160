import React from 'react'
import { Tooltip } from 'react-tooltip'

export default function BarChartItem({
	category,
	data,
	animation,
	id,
	descriptions,
}) {

	let values = Object.entries(data)
	let percent = Object.values(data).reduce((x, y) => x + y)
	percent = percent > 100 ? 100 : Math.round(percent)

	return (
		<div
			id={id}
			className={`bc-item ${animation ? 'active' : ''}`}
			style={{
				height: `${percent}%`,
			}}
		>
			<div className='bc-item__header'>
				<div className="bc-item__category">
					{category}
				</div>
				<div className='bc-item__percent'>
					{percent} %
				</div>
			</div>
			<div className={`bc-item__body bc-item_color-${category}`}>
				{values.map(([key, p], index) => <React.Fragment key={index}>
					<div className={`bc-item__child child-${index + 1}`} id={`type-${key}`} key={index} style={{ height: p * 100 / percent + "%" }}>
						{p > 5 ? key : ''}
					</div>
					<Tooltip
						className='bar-tooltip'
						classNameArrow='bar-tooltip-arrow'
						variant="light"
						anchorSelect={`#type-${key}`}
						style={{ zIndex: 1111, width: '273px', background: '#fff' }}
					>
						<div>
							<b>
								{category} <span>«{key}»</span>
							</b>
							<p>
								{descriptions[index].text}
							</p>
						</div>
					</Tooltip>
				</React.Fragment>

				)}
			</div>

		</div>
	)
}
