import { useState, useMemo } from "react"
import style from "./content.module.css"
import Download from "./button/Download"
import DownloadApk from "./button/DownloadApk"
import GooglePlayIcon from "../../icons/GooglePlayIcon"
import AppStoreIcon from "../../icons/AppStoreIcon"
import Iphone17 from "../../assets/setupApp/iPhone17.png"
import Iphone18 from "../../assets/setupApp/iPhone18.png"

export default function Header() {
    return (

        <div className={style['header']}>
            <div className={['container', style['header__container']].join(' ')}>
                <div className={style['header__left-block']}>
                    <div className="d-flex flex-wrap">
                        <div className={style['header__content']}>
                            <h1>Фиксировать<br />нарушения<br />в моменте</h1>
                            <p className={style['header__caption']}>
                                Первое мобильное приложение для защиты прав клиентов ТК. Фиксируйте нарушения прямо со своего мобильного телефона – и подавайте претензии и жалобы.
                            </p>
                            <div>
                                <div className={style['header__sm-caption']}>
                                    Скачать приложение 
                                </div>
                                <div className={['d-flex justify-content-between flex-wrap', style['header__download-buttons']].join(' ')}>
                                    <Download className={[style['header__download'], 'mb-3'].join(' ')} link="https://apps.apple.com/us/app/книга-жалоб/id6473900373">
                                        <AppStoreIcon />
                                    </Download>
                                    <Download className={[style['header__download'], 'mb-3'].join(' ')} link="https://play.google.com/store/apps/details?id=ru.whiteindex.m.twa">
                                        <GooglePlayIcon />
                                    </Download>
                                    <Download className={[style['header__download'], 'mb-3'].join(' ')} link="https://m.whiteindex.ru/api/files/minio/%D0%9A%D0%BD%D0%B8%D0%B3%D0%B0%20%D0%B6%D0%B0%D0%BB%D0%BE%D0%B1.apk">
                                        <DownloadApk></DownloadApk>
                                    </Download>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={style['header__right-block']}>
                    <div className={style['header__image']}>
                        <img className={style['header__left-image']} src={Iphone17} />
                        <img className={style['header__right-image']} src={Iphone18} />
                    </div>
                </div>
            </div>
        </div>
    )
}