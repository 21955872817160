import style from './article.module.css';
import {useCallback, useEffect, useState} from "react";

export default function ArticleTableOfContents() {
    const [open, setOpen] = useState(true);

    const handleOpen = useCallback(() => {
        setOpen(prev => !prev);
    }, []);

    const scrollTo = useCallback((el) => () => {
        const section = document.getElementById(el);
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }, []);

    useEffect(() => {
        let observer;

        const options = {
            threshold: 0.3,
        };
        observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                const navElement = document.querySelector(
                    `[data-to-scrollspy-id="${entry.target.id}"]`,
                );
                if (entry.isIntersecting) {
                    if (!navElement.classList.contains(style['active'])) {
                        navElement.classList.add(style['active']);
                    }
                } else if (navElement.classList.contains(style['active'])) {
                    navElement.classList.remove(style['active']);
                }
            });
        }, options);

        const articles = document.querySelectorAll(`article[id]`);

        articles.forEach((article) => {
            observer.observe(article);
        });
        return () => observer.disconnect();
    }, []);

    return (
        <div className={style['article-table-contents-block']}>
            <div className={style['article-table-contents-block_content']}>
                <ul className={open ? '' : style['article-table-contents-block_content__hidden']}>
                    <li onClick={handleOpen}>
                        <span>Содержание</span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.9999 10.8284L7.0502 15.7782L5.63599 14.364L11.9999 8L18.3639 14.364L16.9497 15.7782L11.9999 10.8284Z"
                                fill="#18222B"/>
                        </svg>
                    </li>
                    <li data-to-scrollspy-id="about-research" onClick={scrollTo('about-research')} className={style['active']}>Об исследовании</li>
                    <li data-to-scrollspy-id="methodology" onClick={scrollTo('methodology')}>Методология</li>
                    <li data-to-scrollspy-id="market-review" onClick={scrollTo('market-review')}>Обзор рынка</li>
                    <li data-to-scrollspy-id="users" onClick={scrollTo('users')}>Пользователи платформ</li>
                    <li data-to-scrollspy-id="functions" onClick={scrollTo('functions')}>Сервисы и функции</li>
                    <li data-to-scrollspy-id="levels" onClick={scrollTo('levels')}>Уровни развития платформ</li>
                    <li data-to-scrollspy-id="prospects" onClick={scrollTo('prospects')}>Перспективы</li>
                </ul>
            </div>
            <div className={style['article-table-contents-block_share']}>
                <p onClick={scrollTo('questions-form')}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.99996 18.3334C5.39758 18.3334 1.66663 14.6024 1.66663 10C1.66663 5.39765 5.39758 1.66669 9.99996 1.66669C14.6023 1.66669 18.3333 5.39765 18.3333 10C18.3333 14.6024 14.6023 18.3334 9.99996 18.3334ZM9.99996 16.6667C13.6819 16.6667 16.6666 13.6819 16.6666 10C16.6666 6.31812 13.6819 3.33335 9.99996 3.33335C6.31806 3.33335 3.33329 6.31812 3.33329 10C3.33329 13.6819 6.31806 16.6667 9.99996 16.6667ZM9.16663 12.5H10.8333V14.1667H9.16663V12.5ZM10.8333 11.1293V11.6667H9.16663V10.4167C9.16663 9.95644 9.53971 9.58335 9.99996 9.58335C10.6903 9.58335 11.25 9.02369 11.25 8.33335C11.25 7.643 10.6903 7.08335 9.99996 7.08335C9.39354 7.08335 8.88796 7.51521 8.77396 8.08816L7.13938 7.76124C7.40527 6.42435 8.58496 5.41669 9.99996 5.41669C11.6108 5.41669 12.9166 6.72252 12.9166 8.33335C12.9166 9.6546 12.038 10.7707 10.8333 11.1293Z"
                            fill="#18222B"/>
                    </svg>
                    <span>Задать вопрос</span>
                </p>
                <p onClick={async () => {
                    await navigator?.share?.({ url: window.location.href });
                }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.33333 2.5V4.16667H4.16667V15.8333H15.8333V11.6667H17.5V16.6667C17.5 17.1269 17.1269 17.5 16.6667 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V3.33333C2.5 2.8731 2.8731 2.5 3.33333 2.5H8.33333ZM14.6548 4.16667H10.8333V2.5H17.5V9.16667H15.8333V5.34517L10 11.1785L8.8215 10L14.6548 4.16667Z"
                            fill="#18222B"/>
                    </svg>
                    <span>Поделиться</span>
                </p>
            </div>
        </div>
    )
}
