import React from 'react'

function MenuCloseCross() {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8.58588 10.0821L3.79297 14.875L5.20718 16.2892L10.0001 11.4963L14.793 16.2892L16.2072 14.875L11.4143 10.0821L16.2072 5.28921L14.793 3.875L10.0001 8.66788L5.20721 3.875L3.793 5.28921L8.58588 10.0821Z" fill="white" />
		</svg>
	)
}

export default MenuCloseCross