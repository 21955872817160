import React from "react";
import MainPage from "./pages/MainPage";
import TestingRegistration from "./pages/TestingRegistration";
import LayoutDefault from "./components/LayoutDefault/LayoutDefault";
import APIForCarrier from "./pages/APIForCarrier";
import ResearchPage from "./pages/ResearchPage";
import ArticlePage from "./pages/ArticlePage";
import SetupMobileApp from "./pages/SetupMobileApp";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/research/logistics-platforms" element={<ArticlePage />} />
          <Route path="/research" element={<ResearchPage />} />
          <Route path="/get-api" element={<APIForCarrier />} />
          <Route
            path="/registration-for-testing"
            element={<TestingRegistration />}
          />
          <Route path="/setup-app" element={<SetupMobileApp />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
