import React from 'react';
import Footer from '../components/Footer/Footer';
import TopNav from '../components/TopNav/TopNav';
import APIForCarrierContent from '../components/APIForCarrierContent/APIForCarrierContent';

function APIForCarrier() {
  return (
	<div className='carrier-api'>
		<TopNav api />
		<APIForCarrierContent />
		<Footer />
	</div>
  )
}

export default APIForCarrier