import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer/Footer'

import InstrumentsAndServices from '../components/InstrumentsAndServices/InstrumentsAndServices'
import ResearchBlock from '../components/Research/ResearchBlock'
import ResearchHeader from '../components/Research/ResearchHeader'

export default function ResearchPage() {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<div className='main-page'>
			<ResearchHeader></ResearchHeader>
			<ResearchBlock />
			<InstrumentsAndServices />
			<Footer hasForm />
		</div>
	)
}
