import { axiosInstance } from '../API.js'

// готово
export function getRatingTableData({ page, search, group }) {
	return axiosInstance.get(`/companies/`, {
		params: {
			page,
			search,
			group
		}
	})
}

export function getRatingDiagramData(inn) {
	return axiosInstance.get(`/companies/${inn}`)
}

// готово
export function getCompenieById({ id, group }) {
	return axiosInstance.get(`/companies/${id}`, {
		params: {
			group
		}
	})
}

// готово
export function getRatingData({ group }) {
	return axiosInstance.get(`/rating`, {
		params: {
			group
		}
	})
}


// готово
export function getSearchData({ search, group }) {
	return axiosInstance.get(`/search/`, {
		params: {
			search,
			group
		}
	})
}


export function getFile() {
	return axiosInstance.get(`/file/`)
}

export function getTransportGroups() {
	return axiosInstance.get(`/transport-groups/`)
}

export function getResearches() {
	return axiosInstance.get(`/researches/`)
}


// export function setRequest(name, inn, email) {
// 	return axiosInstance.post('/requests/', {
// 		company_name: name,
// 		company_inn: inn,
// 		email: email
// 	})
// }
