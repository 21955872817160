import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from 'react-router-dom';
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";
import ChevronRight from '../../icons/ChevronRight';

export default function InfoSlider() {
	return (
		<div style={{ overflow: 'auto' }}>
			<Swiper
				className="info-slider"
				allowTouchMove={true}
				grabCursor={true}
				slideToClickedSlide={true}
				spaceBetween={20}
				slidesPerView={'auto'}
				// modules={[Pagination]}
				// pagination={
				// 	true
				// }
			>
				<SwiperSlide>
					<a className="info-slider__wrap" href='https://m.whiteindex.ru/violation'>
						<div className='info-slider__sub-header'>оформление претензии</div>
						<div className="info-slider__header">
							Подать претензию к перевозчику
						</div>
						<div className="info-slider__text">
							Поможем оформить претензию к любой ТК и получить компенсацию за поврежденный груз или нарушение сроков доставки.
						</div>
						<div className='info-slider__link'>
							<div className='info-slider__link-wrap'>
								<span>Подача претензии</span>
								<ChevronRight />
							</div>
						</div>
					</a>
				</SwiperSlide>
				<SwiperSlide>
					<Link to='/setup-app' className="info-slider__wrap">
						<div className='info-slider__sub-header'>Мобильное приложение</div>
						<div className="info-slider__header">
							Фиксировать нарушения в моменте
						</div>
						<div className="info-slider__text">
							Первое мобильное приложение для защиты прав клиентов ТК. Фиксируйте нарушения прямо со своего мобильного телефона – и подавайте претензии и жалобы.
						</div>
						<div to='/setup-app' className='info-slider__link long-button'>
							<div className='info-slider__link-wrap'>
								<span>Подробнее</span>
								<ChevronRight />
							</div>
						</div>
					</Link>
				</SwiperSlide>
				<SwiperSlide>
					<Link to='/get-api' className="info-slider__wrap last-slide">
						<div className='info-slider__shadow'>
							<div className='info-slider__sub-header'>подключить API</div>
							<div className="info-slider__header">
								Помощник для перевозчиков
							</div>
							<div className="info-slider__text">
								Уникальный сервис для транспортных компаний, который поможет быстрее рассматривать претензии и улучшать свой рейтинг в WhiteIndex. Оставьте заявку на подключение по API.
							</div>
							<div className='info-slider__link'>
								<div className='info-slider__link-wrap black'>
									<span>Подключение API</span>
									<ChevronRight />
								</div>
							</div>
						</div>
					</Link>
				</SwiperSlide>
			</Swiper >
		</div>
	);
}
