import React, { useEffect, useRef, useState } from 'react'
import TopNav from '../TopNav/TopNav'
import Dash from '../../icons/Dash';
import './style.css'
import {Link} from "react-router-dom";
import style from "../Article/article.module.css";
import AnnounceImg from "../../assets/articleImg/main-announce.png";

export default function HeaderBlock() {

	return (
		<>
			<div className='header-block'>
				<TopNav />
				<div className='container header-block__container'>
					<div className='header-block__info'>
						<h1>
							Рейтинги и исследования <br /> в сфере логистики
						</h1>
						<div className='header-block__caption-wrap'>
							<div className='header-block__caption'>
								<div className='header-block__thesis'>
									<Dash />
									<p className='header-block__text'>
										Уникальный ресурс по подбору качественного перевозчика
									</p>
								</div>
								<div className='header-block__thesis'>
									<Dash />
									<p className='header-block__text'>
										Надежные инструменты для защиты прав потребителей
									</p>
								</div>
								<div className='header-block__thesis'>
									<Dash />
									<p className='header-block__text'>
										Системы для улучшения работы транспортных компаний с претензиями
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="article-announce-block">
				<div>
					<h3>Логистические платформы как маркетплейсы</h3>
					<p>Новое исследование оценивает развитие и эффективность более 100 сервисов в 25 логистических
						платформах в России.
					</p>
					<Link to="/research/logistics-platforms">
						<button>Перейти к исследованию</button>
					</Link>
				</div>
				<img className="article-announce-img" src={AnnounceImg} alt=""/>
			</div>
		</>
	)
}
