import { axiosInstance } from '../API.js'

export function registrationForTesting(email) {
	return axiosInstance.post('/registration/testing/', {
		email: email
	})
}

export function registrationForAPI(name, company, email, phone) {
	return axiosInstance.post('/registration/form/', {
		name: name,
		company: company,
		email: email,
		phone: phone
	})
}

export function sendFeedback(name, question, email) {
	return axiosInstance.post('/requests/', {
		name: name,
		email: email,
		question: question
	})
}