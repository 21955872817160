import { useState, useMemo } from "react"
import style from "./content.module.css"
import IosQr from "../../assets/setupApp/iosQR.png"
import IosShare from "../../assets/setupApp/iosShare.png"
import IosHome from "../../assets/setupApp/iosHome.png"
import AndroidQr from "../../assets/setupApp/androidQR.png"
import AndroidShare from "../../assets/setupApp/androidShare.png"
import AndroidHome from "../../assets/setupApp/androidHome.png"
import DropArrow from '../../icons/DropArrow'

export default function Instruction() {
    const [isOpenIos, setOpenIos] = useState(true)
    // const [isOpenAndroid, setOpenAndroid] = useState(false)
    return (
        <div className={style['instruction-block']}>
            <div className="container">
                <div className="mt-5 mb-5">
                    <div className={style['dropdown-title']} onClick={() => setOpenIos(!isOpenIos)}>
                        <div className={[style['instruction-title']].join(' ')}>
                            Инструкция установки веб-версии приложения для iOS
                        </div>
                        <span className={isOpenIos ? style['drop-down--open'] : style['drop-down--close']}>
                            <DropArrow />
                        </span>
                    </div>
                </div>
                {
                    isOpenIos ? <div className={['row', style['steps']].join(' ')}>
                        <div className={['col-lg-4'].join(' ')}>
                            <div className={style['step']}>
                                <img className={style['qr']} src={IosQr} />
                            </div>
                            <div className={style['step__caption']}>
                                Отcканируйте Qr-код <br />или перейдите по <a className={style['step__download-link']} href="https://m.whiteindex.ru" target="_blank">ссылке</a>
                            </div>
                        </div>
                        <div className={['col-lg-4'].join(' ')}>
                            <div className={style['step']}>
                                <img className={style['share']} src={IosShare} />
                            </div>
                            <div className={style['step__caption']}>
                                Нажмите кнопку <br />«Поделиться»
                            </div>
                        </div>
                        <div className={['col-lg-4'].join(' ')}>
                            <div className={style['step']}>
                                <img className={style['home']} src={IosHome} />
                            </div>
                            <div className={style['step__caption']}>
                                Нажмите кнопку <br />«На экран Домой»
                            </div>
                        </div>
                    </div> : null
                }

                <div className="mt-5 mb-5">
                    <div className={style['dropdown-title']} onClick={() => setOpenIos(!isOpenIos)}>
                        <div className={[style['instruction-title']].join(' ')}>
                            Инструкция установки веб-версии приложения для Android
                        </div>
                        <span className={!isOpenIos ? style['drop-down--open'] : style['drop-down--close']}>
                            <DropArrow />
                        </span>
                    </div>
                </div>

                {
                    !isOpenIos ? <div className={['row flex-wrap', style['steps']].join(' ')}>
                        <div className={['col-lg-4'].join(' ')}>
                            <div className={style['step']}>
                                <img className={style['qr']} src={AndroidQr} />
                            </div>
                            <div className={style['step__caption']}>
                                Отcканируйте Qr-код <br />или перейдите по <a className={style['step__download-link']} href="https://m.whiteindex.ru" target="_blank">ссылке</a>
                            </div>
                        </div>
                        <div className={['col-lg-4'].join(' ')}>
                            <div className={style['step']}>
                                <img className={style['share']} src={AndroidShare} />
                            </div>
                            <div className={style['step__caption']}>
                                Нажмите на «три точки» <br />вверху экрана
                            </div>
                        </div>
                        <div className={['col-lg-4'].join(' ')}>
                            <div className={style['step']}>
                                <img className={style['home']} src={AndroidHome} />
                            </div>
                            <div className={style['step__caption']}>
                                Нажмите кнопку <br />«Добавить на главный экран»
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        </div>
    )
}