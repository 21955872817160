import React from 'react'
import './style.css'
import LogoMini from '../../icons/LogoMini.jsx'
import FormField from '../FormField/FormField';
import { sendFeedback } from '../../services/forms';
import { useState, useRef } from 'react';

export default function Footer(props) {

	const {
		hasForm
	} = props

	const year = new Date().getFullYear();

	const nameRef = useRef(null);
	const [nameValue, setNameValue] = useState('');

	const emailRef = useRef(null);
	const [emailValue, setEmailValue] = useState('');

	const questionRef = useRef(null);
	const [questionValue, setQuestionValue] = useState('');

	const [nameError, setNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [questionError, setQuestionError] = useState('');

	const [sent, setSent] = useState(false);

	const sendFeedbackData = async function () {
		let name = nameValue;
		let email = emailValue;
		let question = questionValue;

		try {
			const res = await sendFeedback(name, question, email);
			if (res !== undefined) {
				nameRef.current.value = '';
				setNameValue('');
				questionRef.current.value = '';
				setQuestionValue('');
				emailRef.current.value = '';
				setEmailValue('');
				setSent(true);
			}
		} catch (error) {
			console.log(error.response.data);
			if (error.response.data.name) {
				setNameError(error.response.data.name[0])
			}
			if (error.response.data.question) {
				setQuestionError(error.response.data.question[0])
			}
			if (error.response.data.email) {
				setEmailError(error.response.data.email[0])
			}
		}
	}

	return (
		<div className='footer__wrap'>
			{hasForm ?
				<div className='footer__form container' id="questions-form">
					<div className='container'>
						{sent ?
							<div className='footer__form-sent'>
								<p className='footer__form-sent-header'>Отправлено</p>
								<p className='footer__form-sent-text'>Скоро мы ответим на ваш вопрос</p>
								<button onClick={() => setSent(false)} className='footer__form-sent-button'>Хорошо</button>
							</div> :
							<div className='footer__form-wrap'>
								<div className='footer__form-info'>
									<p className='footer__form-header'>Свяжитесь с нами, если у вас есть вопросы по рейтингу или сервисам.</p>
									<p className='footer__form-text'>Наша команда всегда на связи!</p>
								</div>
								<div className='footer__form-fields'>
									<div className='footer__form-fields-inputs'>
										<div className='footer__form-fields-input name'>
											<FormField
												caption='Имя'
												inputRef={nameRef}
												setInputValue={setNameValue}
												placeholder_text='Имя'
												error={nameError}
												setError={setNameError}
											/>
										</div>
										<div className='footer__form-fields-input email'>
											<FormField
												caption='Электронная почта'
												inputRef={emailRef}
												setInputValue={setEmailValue}
												placeholder_text='Электронная почта'
												error={emailError}
												setError={setEmailError}
											/>
										</div>
									</div>
									<div className='footer__form-fields-input textarea'>
										<FormField
											type='textarea'
											inputRef={questionRef}
											setInputValue={setQuestionValue}
											placeholder_text='Ваш вопрос'
											error={questionError}
											setError={setQuestionError}
										/>
									</div>
									<button onClick={sendFeedbackData} className='footer__form-button'>
										Отправить вопрос
									</button>
								</div>
							</div>
						}
					</div>
				</div>
				: null
			}
			<div className={`footer${hasForm ? ' has-form' : ''}`}>
				<div className='container'>
					<div className='footer__top'>
						<div className='footer__top-wrap'>
							<div className='footer__top-caption-wrap'>
								<div className='footer__logo'>
									<div className='footer__logo-main'>
										Общественная Потребительская Инициатива
									</div>
									<div className='footer__logo-second'>
										Организация по защите прав потребителей
									</div>
									<div className='footer__logo-pic'>
										<LogoMini />
									</div>
								</div>
								<div className='footer__top-caption-footer'>
									<div className='footer__top-links'>
										<a href="tel:+74953745715">+7 (495) 374-57-15</a>
										<a href="mailto:info@whiteindex.ru">info@whiteindex.ru</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='footer__center'>
						<div className='footer__contacts'>
							<div className='footer__contacts-item'>
								<a href="tel:+74953745715">+7 (495) 374-57-15</a>
							</div>
							<div className='footer__contacts-item'>
								<a href="mailto:info@whiteindex.ru">info@whiteindex.ru</a>
							</div>
						</div>
					</div>
					<div className='footer__bottom'>
						<div className='footer__inn-and-address'>
							<p className='footer__address'>109382, г. Москва, Мариупольская ул., д. 6, офис 30</p>
							<p className='footer__inn'>РООП «Общественная Потребительская Инициатива», ИНН 7723212627, ОГРН 1147799005804</p>
						</div>
						<div className='footer__copyright-and-policy'>
							<p className='footer__policy'><a href='https://m.whiteindex.ru/privacy-policy' target='_blank' className='footer__policy-link'>Политика конфиденциальности</a></p>
							<p className='footer__copyright'>&copy;&nbsp;Общественная потребительская инициатива, {year}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
