import FormField from '../FormField/FormField'
import { useState, useRef, useEffect } from 'react'
import { registrationForAPI } from '../../services/forms';
import Dash from '../../icons/Dash';
import './styles.css'

function APIForCarrierContent() {

	const nameRef = useRef(null);
	const [nameValue, setNameValue] = useState('');

	const companyRef = useRef(null);
	const [companyValue, setCompanyValue] = useState('');


	const emailRef = useRef(null);
	const [emailValue, setEmailValue] = useState('');

	const phoneRef = useRef(null);
	const [phoneValue, setPhoneValue] = useState('');

	const [sent, setSent] = useState(false);

	const [nameError, setNameError] = useState('');
	const [companyError, setCompanyError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [phoneError, setPhoneError] = useState('');


	const sendInfoForApi = async function () {
		let name = nameValue;
		let company = companyValue;
		let email = emailValue;
		let phone = phoneValue;

		try {
			const res = await registrationForAPI(name, company, email, phone);
			if (res !== undefined) {
				nameRef.current.value = '';
				setNameValue('');
				companyRef.current.value = '';
				setCompanyValue('');
				emailRef.current.value = '';
				setEmailValue('');
				phoneRef.current.value = '';
				setPhoneValue('');
				setSent(true);
				localStorage.apiFormIsSent = true;
			}
		} catch (error) {
			console.log(error.response.data);
			if (error.response.data.name) {
				setNameError(error.response.data.name[0])
			}
			if (error.response.data.company) {
				setCompanyError(error.response.data.company[0])
			}
			if (error.response.data.email) {
				setEmailError(error.response.data.email[0])
			}
			if (error.response.data.phone) {
				setPhoneError(error.response.data.phone[0])
			}
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

	return (
		<div className='carrier-api-content__info'>
			<div className='carrier-api-content__left-block'>
				<div className='carrier-api-content__center-block left'>
					<div className='carrier-api-content__block-wrap'>
						<p className='carrier-api-content__header'>
							Помощник для перевозчиков
						</p>
						<div className='carrier-api-content__advantages'>
							<p className='carrier-api-content__text'>Уникальный сервис для транспортных компаний, который поможет быстрее рассматривать претензии и улучшать свой рейтинг в WhiteIndex. Оставьте заявку на подключение по API.</p>
							{/* <div className='carrier-api-content__text with-dash'>
								<span>Возможность быстрее получать претензии и отвечать клиентам</span>
								<div className='carrier-api-content__dash'>
									<Dash />
								</div>
							</div>
							<div className='carrier-api-content__text with-dash'>
								<span>Снижение издержек и претензионно-исковой нагрузки</span>
								<div className='carrier-api-content__dash'>
									<Dash />
								</div>
							</div>
							<div className='carrier-api-content__text with-dash'>
								<span>Повышение клиентоориентированности и улучшение рейтинга</span>
								<div className='carrier-api-content__dash'>
									<Dash />
								</div>
							</div>
							<p className='carrier-api-content__text'>
								Заполните форму и в течение 2 рабочих дней наш специалист свяжется с вами
							</p> */}
						</div>
					</div>
				</div>
			</div>
			<div className='carrier-api-content__right-block'>
				<div className='carrier-api-content__center-block right'>
					<div className='carrier-api-content__block-wrap'>
						{!sent && !localStorage.apiFormIsSent ?
							(<>
								<div className='carrier-api-content__field-wrap'>
									<FormField
										caption='Имя Фамилия'
										inputRef={nameRef}
										setInputValue={setNameValue}
										placeholder_text='Иван Сидоров'
										error={nameError}
										setError={setNameError}
									/>
								</div>
								<div className='carrier-api-content__field-wrap'>
									<FormField
										caption='Компания'
										inputRef={companyRef}
										setInputValue={setCompanyValue}
										placeholder_text='Название компании'
										error={companyError}
										setError={setCompanyError}
									/>
								</div>
								<div className='carrier-api-content__field-wrap'>
									<FormField
										caption='Электронная почта'
										inputRef={emailRef}
										setInputValue={setEmailValue}
										placeholder_text='Электронная почта'
										error={emailError}
										setError={setEmailError}
									/>
									<p className='carrier-api-content__field-bottom-caption'>На этот адрес отправим подробную информацию</p>
								</div>
								<div className='carrier-api-content__field-wrap'>
									<FormField
										caption='Телефон (необязательно)'
										inputRef={phoneRef}
										setInputValue={setPhoneValue}
										placeholder_text='+7'
										error={phoneError}
										setError={setPhoneError}
									/>
								</div>
								<button className='carrier-api-content__sign-up'
									onClick={() => sendInfoForApi()}
								>
									Отправить
								</button>
							</>) :
							sent && localStorage.apiFormIsSent ?
								(
									<div className='carrier-api-content__mail-sent'>
										<p className='carrier-api-content__mail-sent-header'>
											Отправлено
										</p>
										<p className='carrier-api-content__mail-sent-text'>
											Ожидайте письмо на указанную почту
										</p>
									</div>
								)
								:
								(
									<div className='carrier-api-content__mail-sent'>
										<p className='carrier-api-content__mail-sent-text'>
											Вы уже отправили контактные данные
										</p>
									</div>
								)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default APIForCarrierContent