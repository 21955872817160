import React from 'react'
import Footer from '../components/Footer/Footer';
import TopNav from '../components/TopNav/TopNav';
import'./styles.css';
import TestingRegistrationContent from '../components/TestingRegistrationContent/TestingRegistrationContent'

function TestingRegistration() {
  return (
	<div className='testing-registration'>
		<TopNav testing />
		<TestingRegistrationContent />
		<Footer />
	</div>
  )
}

export default TestingRegistration