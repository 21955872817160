import React, { useCallback, useState, useEffect, useRef } from 'react'
import './style.css'
import BarChartDiagram from '../BarChartDiagram/BarChartDiagram'
import { getRatingData } from '../../services/ratingData'

export default function BarChart({ group, description }) {
	const prevGroup = useRef();
	const [data, setData] = useState(null)
	const getData = async () => {
		try {
			const { data: res } = await getRatingData({ group });
			setData(res);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (prevGroup.current !== group) {
			getData();
		}
	}, [group])

	return (
		<>
			<div className='bar-chart__wrap'>
				<div className='bar-chart__header'>
					{ description }
				</div>
				<div className='bar-chart__text-wrap'>
					<div className='bar-chart__text'>
						Методика расчета предполагает разделение рынка на 3 больших блока: надежные, сомнительные и рискованные для сотрудничества компании.
					</div>
					{data?.count && (
						<div className="bar-chart__under-diagram-rigth">
							Рейтинг рассчитан на&nbsp;основании результатов анализа данных в&nbsp;отношении {data?.count} перевозчиков
						</div>
					)}
				</div>
				{data && <BarChartDiagram data={data} />}
			</div>
		</>
	)
}