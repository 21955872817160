import React from 'react'

export default function LogoMini() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="51" height="46" viewBox="0 0 51 46" fill="none">
			<path d="M15.998 5.00041C5.60412 11.0886 1.38618 26.6081 1.02553 34.3991C1.00125 34.9237 1.70555 35.0716 1.97389 34.6203C5.84262 28.1133 12.6948 27.4915 16.1982 28.1069C16.4313 28.1478 16.665 28.0281 16.7672 27.8147C20.6823 19.6424 30.4775 16.9233 35.0597 17.4389C35.3109 17.4672 35.5547 17.3205 35.6461 17.0848C36.3332 15.312 39.5792 8.48572 48.7764 8.62533C49.276 8.63291 49.521 8.00192 49.1048 7.72549C41.7259 2.82469 26.5657 -1.18952 15.998 5.00041Z" stroke="white" strokeWidth="2" />
			<path d="M16.4992 28C15.8223 26.6461 13.9996 22.5427 14.2886 16C14.35 14.6092 14.5069 13.1082 14.7904 11.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
			<path d="M35.2879 17.1911C34.4538 15.9279 31.8115 12.2978 26.0009 9.27667C24.7657 8.63448 23.3874 8.01982 21.8528 7.46125" stroke="white" strokeWidth="2" strokeLinecap="round" />
			<path d="M35.498 36L28.1642 22.7073C27.7951 22.0384 26.9564 21.791 26.2833 22.1526C25.5901 22.5249 25.3408 23.3943 25.7342 24.0758L33.3646 37.2919C33.6784 37.8355 37.2774 45.3244 42.0608 42.5627C44.7197 41.0276 44.0067 38.0075 42.915 35.4319C42.6962 34.9159 42.0849 34.7039 41.5823 34.952L41.3346 35.0743C40.853 35.3121 40.6499 35.8862 40.8374 36.3895C41.424 37.964 41.6872 39.5895 40.6799 40.171C38.5992 41.3724 36.5371 37.8 35.5235 36.044L35.498 36Z" fill="white" />
		</svg>
	)
}
