import React from 'react';
import './styles.css';


function Tabs(props) {
	const {
		activeTab,
		setActiveTab,
		tabsList,
		disabled,
	} = props

	return (
		<div className='tabs'>
			{tabsList.map((tab, index) => {
				return (
					<button disabled={disabled == tab.name} className={`tabs__tab ${tab.name == activeTab.name ? 'active' : ''}`} key={index} onClick={() => setActiveTab(tab)}>
						{tab.text}
					</button>
				)
			})}
		</div>
	)
}

export default Tabs