import React, { useState, useEffect } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import Logo from '../../icons/Logo.jsx'
import Menu from '../../icons/Menu.jsx'
import MenuCloseCross from '../../icons/MenuCloseCross.jsx'

export default function TopNav(props) {
	const {
		testing,
		api,
		classes = []
	} = props

	// const [active, setActive] = useState(false)

	// let base = document.querySelector('body');

	// useEffect(() => {
	// 	if (active) {
	// 		base.classList.add('no-scroll');
	// 	} else {
	// 		base.classList.remove('no-scroll');
	// 	}
	// }, [active])

	const [winHeight, setWinHeight] = useState(null);
	const [active, setActive] = useState(false);

	window.addEventListener('resize', function () {
		setWinHeight(window.innerHeight);
	})
	useEffect(() => {
		setWinHeight(window.innerHeight);
	}, [])
	useEffect(() => {
		document.querySelector('.topnav__mob-menu-content').style.setProperty('--vh', winHeight * 0.01 + 'px');
	}, [winHeight])

	const toggleActive = () => {
		setActive(!active)
	}

	useEffect(() => {
		if (active) {
			document.querySelector('body').style.overflow = 'hidden';
		} else {
			document.querySelector('body').style.overflow = 'auto';
		}
	}, [active])


	return (
		<div className={
			[
				`topnav${testing ? ' testing' : api ? ' api' : ''}${active ? ' active-menu' : ''}`,
				...classes
			].join(' ')
		}>
			<div className={`topnav__wrap`}>
				<div className="container">
					<div className='topnav__logo'>
						<Link to='/'>
							<Logo />
						</Link>
					</div>
					<div className='topnav__contacts'>
						<div className='topnav__menu topnav__menu-desktop'>
							{
								!api ?
									(<div className='topnav__menu-item' onClick={() => setActive(false)}>
										<Link to='/research'>
											<span>Исследования</span>
										</Link>
									</div>)
									// (<div className='topnav__menu-item'>
									// 	<Link to='/get-api'>
									// 		<span>API для перевозчика</span>
									// 	</Link>
									// </div>)
									: null
							}
							{
								!testing ?
									(<div className='topnav__menu-item'>
										<a href='https://m.whiteindex.ru/violation'>Подать претензию</a>
									</div>)
									: null
							}
						</div>
						<a className='topnav__lk' href='https://m.whiteindex.ru/auth/login'>Войти</a>
						<button onClick={toggleActive} className={`topnav__menu-button${active ? ' active' : ''}`}>
							<div className='topnav__menu-button-icon open'>
								<Menu />
							</div>
							<div className='topnav__menu-button-icon close'>
								<MenuCloseCross />
							</div>
						</button>
					</div>
				</div>
			</div>
			<div className={`topnav__mob-menu${active ? ' active' : ''}`}>
				<div className='topnav__mob-menu-wrap container'>
					<div className="topnav__mob-menu-content">
						{
							!testing ?
								(<div className='topnav__menu-item' onClick={() => setActive(false)}>
									<a href='https://m.whiteindex.ru/violation'>Подать претензию</a>
								</div>)
								: null
						}
						{
							!api ?
								// (<div className='topnav__menu-item' onClick={() => setActive(false)}>
								// 	<Link to='/research'>
								// 		<span>Исследования</span>
								// 	</Link>
								// </div>)
								(<div className='topnav__menu-item' onClick={() => setActive(false)}>
									<Link to='/research'>
										<span>Исследования</span>
									</Link>
								</div>)
								: null
						}
						<div className='topnav__menu-contacts'>
							<p className='topnav__menu-header'>Контакты</p>
							<a className='topnav__menu-link' href='tel:84953745715' onClick={() => setActive(false)}>+7 (495) 374-57-15</a>
							<a className='topnav__menu-link' href='mailto:info@whiteindex.ru' onClick={() => setActive(false)}>info@whiteindex.ru</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
