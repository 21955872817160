import React from 'react'

function Dash() {
  return (
	<svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18 12.8723V14.5279H0V12.8723H18Z" fill="white"/>
	</svg>
  )
}

export default Dash