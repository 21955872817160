import React from 'react'

function AppStoreIcon() {
    return (
        <svg width="117" height="33" viewBox="0 0 117 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.527 15.8506C20.4939 12.2592 23.543 10.512 23.6825 10.4307C21.9556 7.9759 19.279 7.6405 18.3385 7.61376C16.0905 7.3831 13.9099 8.92527 12.7648 8.92527C11.5968 8.92527 9.83332 7.63605 7.93275 7.67393C5.48704 7.7107 3.19904 9.0913 1.94418 11.2352C-0.645535 15.6065 1.28589 22.0304 3.76704 25.5638C5.00818 27.2943 6.45846 29.2264 8.35675 29.1585C10.2139 29.0838 10.9076 28.0041 13.1488 28.0041C15.3693 28.0041 16.0208 29.1585 17.9568 29.115C19.9499 29.0838 21.2047 27.3767 22.4025 25.6306C23.8367 23.6472 24.4128 21.6939 24.4356 21.5936C24.3888 21.578 20.5648 20.155 20.527 15.8506Z" fill="white" />
            <path d="M16.8699 5.28936C17.8688 4.07145 18.5522 2.4145 18.3625 0.733047C16.9168 0.795447 15.1088 1.70805 14.0676 2.89922C13.1465 3.94888 12.3236 5.66933 12.5362 7.28728C14.1602 7.40539 15.8276 6.48833 16.8699 5.28936Z" fill="white" />
            <path d="M46.0567 28.3697H43.7348L42.4629 24.4729H38.042L36.8304 28.3697H34.5698L38.9499 15.1035H41.6552L46.0567 28.3697ZM42.0795 22.8381L40.9293 19.374C40.8076 19.0201 40.5796 18.1867 40.2432 16.8749H40.2023C40.0684 17.4391 39.8527 18.2725 39.5562 19.374L38.4264 22.8381H42.0795V22.8381Z" fill="white" />
            <path d="M57.3217 23.4685C57.3217 25.0953 56.8708 26.3813 55.9691 27.3253C55.1613 28.1657 54.1583 28.5854 52.9611 28.5854C51.6688 28.5854 50.7404 28.1328 50.175 27.2276H50.1341V32.2668H47.9543V21.9523C47.9543 20.9295 47.9267 19.8798 47.8735 18.8032H49.7906L49.9122 20.3194H49.9531C50.6801 19.177 51.7833 18.6068 53.2637 18.6068C54.4211 18.6068 55.3873 19.0524 56.1602 19.9446C56.9352 20.8378 57.3217 22.0121 57.3217 23.4685ZM55.101 23.5462C55.101 22.6152 54.8863 21.8476 54.4548 21.2435C53.9835 20.6135 53.3506 20.2985 52.5572 20.2985C52.0194 20.2985 51.5307 20.4739 51.0942 20.8198C50.6566 21.1687 50.3703 21.6243 50.2363 22.1885C50.1689 22.4517 50.1351 22.667 50.1351 22.8365V24.4314C50.1351 25.1273 50.3539 25.7144 50.7915 26.1939C51.2291 26.6734 51.7976 26.9126 52.4969 26.9126C53.3179 26.9126 53.9569 26.6036 54.414 25.9875C54.872 25.3705 55.101 24.557 55.101 23.5462Z" fill="white" />
            <path d="M68.6049 23.4685C68.6049 25.0953 68.154 26.3813 67.2512 27.3253C66.4445 28.1657 65.4415 28.5854 64.2443 28.5854C62.9519 28.5854 62.0236 28.1328 61.4592 27.2276H61.4183V32.2668H59.2385V21.9523C59.2385 20.9295 59.2109 19.8798 59.1577 18.8032H61.0748L61.1964 20.3194H61.2373C61.9632 19.177 63.0664 18.6068 64.5479 18.6068C65.7043 18.6068 66.6705 19.0524 67.4455 19.9446C68.2174 20.8378 68.6049 22.0121 68.6049 23.4685ZM66.3842 23.5462C66.3842 22.6152 66.1684 21.8476 65.737 21.2435C65.2656 20.6135 64.6348 20.2985 63.8404 20.2985C63.3016 20.2985 62.8139 20.4739 62.3763 20.8198C61.9387 21.1687 61.6534 21.6243 61.5195 22.1885C61.453 22.4517 61.4183 22.667 61.4183 22.8365V24.4314C61.4183 25.1273 61.6371 25.7144 62.0726 26.1939C62.5102 26.6724 63.0787 26.9126 63.7801 26.9126C64.6011 26.9126 65.2401 26.6036 65.6971 25.9875C66.1552 25.3705 66.3842 24.557 66.3842 23.5462Z" fill="white" />
            <path d="M81.2243 24.6489C81.2243 25.7774 80.8225 26.6955 80.0158 27.4043C79.1294 28.1788 77.8953 28.5656 76.3095 28.5656C74.8454 28.5656 73.6717 28.2905 72.7832 27.7392L73.2883 25.9678C74.2453 26.532 75.2953 26.8151 76.4394 26.8151C77.2604 26.8151 77.8994 26.6337 78.3585 26.2728C78.8155 25.912 79.0435 25.4275 79.0435 24.8234C79.0435 24.2851 78.8554 23.8315 78.4781 23.4637C78.1028 23.0958 77.4761 22.7539 76.6009 22.4379C74.2187 21.5716 73.0286 20.3026 73.0286 18.6339C73.0286 17.5433 73.4457 16.6491 74.2811 15.9533C75.1133 15.2565 76.2236 14.9086 77.6121 14.9086C78.8502 14.9086 79.8788 15.119 80.6998 15.5386L80.1548 17.2712C79.388 16.8645 78.521 16.6611 77.5507 16.6611C76.7839 16.6611 76.1848 16.8455 75.7554 17.2124C75.3924 17.5403 75.2104 17.9401 75.2104 18.4136C75.2104 18.9379 75.418 19.3716 75.8351 19.7125C76.1981 20.0275 76.8575 20.3684 77.8145 20.7363C78.9852 21.1958 79.8451 21.7331 80.3982 22.3492C80.9493 22.9633 81.2243 23.7318 81.2243 24.6489Z" fill="white" />
            <path d="M88.4311 20.3985H86.0284V25.0429C86.0284 26.2242 86.4517 26.8143 87.3003 26.8143C87.6898 26.8143 88.0129 26.7814 88.2685 26.7156L88.3288 28.3295C87.8994 28.486 87.334 28.5648 86.6337 28.5648C85.7728 28.5648 85.1 28.3086 84.6144 27.7972C84.1308 27.2848 83.8874 26.4255 83.8874 25.2183V20.3965H82.4561V18.8015H83.8874V17.0501L86.0284 16.42V18.8015H88.4311V20.3985Z" fill="white" />
            <path d="M99.2718 23.5072C99.2718 24.9776 98.8404 26.1848 97.9795 27.1288C97.0767 28.1008 95.8784 28.5852 94.3847 28.5852C92.9451 28.5852 91.799 28.1197 90.9442 27.1886C90.0895 26.2576 89.6621 25.0823 89.6621 23.6657C89.6621 22.1834 90.1017 20.9692 90.9841 20.0252C91.8644 19.0802 93.0525 18.6077 94.5462 18.6077C95.9858 18.6077 97.1442 19.0732 98.0183 20.0053C98.8547 20.9094 99.2718 22.0767 99.2718 23.5072ZM97.0102 23.576C97.0102 22.6938 96.817 21.9372 96.4254 21.3062C95.9684 20.5426 95.3151 20.1618 94.4685 20.1618C93.5923 20.1618 92.9267 20.5436 92.4697 21.3062C92.0781 21.9382 91.8849 22.7067 91.8849 23.6159C91.8849 24.4981 92.0781 25.2547 92.4697 25.8847C92.941 26.6483 93.5994 27.0291 94.4491 27.0291C95.2813 27.0291 95.9347 26.6404 96.406 25.8648C96.8078 25.2218 97.0102 24.4572 97.0102 23.576Z" fill="white" />
            <path d="M106.356 20.6723C106.14 20.6334 105.91 20.6135 105.669 20.6135C104.902 20.6135 104.309 20.8956 103.892 21.4608C103.529 21.9592 103.347 22.5892 103.347 23.3499V28.369H101.168L101.189 21.8157C101.189 20.7132 101.161 19.7093 101.107 18.8042H103.006L103.085 20.6344H103.146C103.376 20.0054 103.739 19.499 104.236 19.1192C104.721 18.7773 105.246 18.6068 105.811 18.6068C106.013 18.6068 106.195 18.6208 106.356 18.6457V20.6723Z" fill="white" />
            <path d="M116.102 23.134C116.102 23.5148 116.077 23.8358 116.023 24.098H109.483C109.509 25.043 109.825 25.7657 110.432 26.2641C110.983 26.7097 111.696 26.933 112.571 26.933C113.539 26.933 114.423 26.7825 115.217 26.4805L115.559 27.9558C114.63 28.3506 113.534 28.5469 112.269 28.5469C110.748 28.5469 109.554 28.1103 108.685 27.2381C107.818 26.3658 107.383 25.1945 107.383 23.7251C107.383 22.2827 107.787 21.0815 108.596 20.1235C109.442 19.1007 110.587 18.5893 112.026 18.5893C113.44 18.5893 114.511 19.1007 115.238 20.1235C115.813 20.9359 116.102 21.9408 116.102 23.134ZM114.024 22.5827C114.038 21.9527 113.896 21.4084 113.601 20.9489C113.223 20.3578 112.644 20.0627 111.864 20.0627C111.151 20.0627 110.571 20.3508 110.128 20.929C109.766 21.3885 109.55 21.9398 109.483 22.5817H114.024V22.5827Z" fill="white" />
            <path d="M41.3534 6.9419C41.3534 8.1152 40.9925 8.99842 40.2717 9.59155C39.604 10.1388 38.6552 10.413 37.4263 10.413C36.8169 10.413 36.2955 10.387 35.8589 10.3352V3.92441C36.4284 3.83469 37.0418 3.78883 37.7044 3.78883C38.875 3.78883 39.7574 4.03705 40.3524 4.53349C41.019 5.09472 41.3534 5.89719 41.3534 6.9419ZM40.2236 6.97081C40.2236 6.2102 40.0171 5.62704 39.604 5.22032C39.191 4.8146 38.5877 4.61124 37.7933 4.61124C37.4559 4.61124 37.1686 4.63317 36.9304 4.67903V9.55267C37.0623 9.57261 37.3036 9.58158 37.6543 9.58158C38.4742 9.58158 39.1071 9.35928 39.5529 8.91468C39.9987 8.47008 40.2236 7.82213 40.2236 6.97081Z" fill="white" />
            <path d="M47.345 7.96701C47.345 8.68973 47.1334 9.28187 46.7101 9.7464C46.2664 10.2239 45.6785 10.4621 44.9444 10.4621C44.2369 10.4621 43.6735 10.2339 43.2533 9.77531C42.8341 9.31775 42.6245 8.74057 42.6245 8.04476C42.6245 7.31706 42.8402 6.71994 43.2737 6.2564C43.7073 5.79286 44.29 5.56059 45.0241 5.56059C45.7316 5.56059 46.3001 5.78887 46.7306 6.24643C47.1395 6.69103 47.345 7.26522 47.345 7.96701ZM46.2337 8.0009C46.2337 7.56727 46.1375 7.19544 45.9464 6.88542C45.7214 6.5106 45.4014 6.32319 44.9853 6.32319C44.5548 6.32319 44.2277 6.5106 44.0027 6.88542C43.8105 7.19544 43.7154 7.57325 43.7154 8.01984C43.7154 8.45348 43.8115 8.8253 44.0027 9.13533C44.2348 9.51015 44.5579 9.69756 44.9751 9.69756C45.384 9.69756 45.7051 9.50716 45.9361 9.12536C46.1345 8.80936 46.2337 8.43454 46.2337 8.0009Z" fill="white" />
            <path d="M55.3737 5.65669L53.8657 10.3559H52.8842L52.2595 8.31532C52.101 7.80592 51.9722 7.29952 51.872 6.7971H51.8525C51.7595 7.31347 51.6307 7.81888 51.465 8.31532L50.8015 10.3559H49.8087L48.3906 5.65669H49.4918L50.0367 7.89065C50.1686 8.41899 50.277 8.9224 50.3639 9.3989H50.3833C50.4631 9.00614 50.595 8.50572 50.781 7.90062L51.465 5.65769H52.3382L52.9936 7.85277C53.152 8.38809 53.2809 8.90346 53.38 9.3999H53.4097C53.4823 8.91642 53.5917 8.40105 53.7369 7.85277L54.3217 5.65769H55.3737V5.65669Z" fill="white" />
            <path d="M60.9321 10.3559H59.8607V7.66433C59.8607 6.83494 59.5376 6.42025 58.8894 6.42025C58.5714 6.42025 58.3148 6.53389 58.1154 6.76217C57.9181 6.99045 57.8179 7.25961 57.8179 7.56764V10.3549H56.7464V6.99943C56.7464 6.58673 56.7331 6.13914 56.7075 5.65466H57.6492L57.6993 6.38935H57.7289C57.8536 6.16107 58.0397 5.97266 58.2841 5.82214C58.5745 5.64669 58.8996 5.55797 59.2554 5.55797C59.7052 5.55797 60.0794 5.69952 60.377 5.98363C60.7471 6.33153 60.9321 6.85089 60.9321 7.54072V10.3559V10.3559Z" fill="white" />
            <path d="M63.883 10.355H62.8125V3.49965H63.883V10.355Z" fill="white" />
            <path d="M70.1927 7.96701C70.1927 8.68973 69.981 9.28187 69.5578 9.7464C69.114 10.2239 68.5251 10.4621 67.792 10.4621C67.0835 10.4621 66.5201 10.2339 66.101 9.77531C65.6818 9.31775 65.4722 8.74057 65.4722 8.04476C65.4722 7.31706 65.6879 6.71994 66.1214 6.2564C66.5549 5.79286 67.1377 5.56059 67.8708 5.56059C68.5793 5.56059 69.1467 5.78887 69.5782 6.24643C69.9872 6.69103 70.1927 7.26522 70.1927 7.96701ZM69.0803 8.0009C69.0803 7.56727 68.9842 7.19544 68.793 6.88542C68.5691 6.5106 68.248 6.32319 67.8329 6.32319C67.4015 6.32319 67.0743 6.5106 66.8504 6.88542C66.6582 7.19544 66.5631 7.57325 66.5631 8.01984C66.5631 8.45348 66.6592 8.8253 66.8504 9.13533C67.0825 9.51015 67.4056 9.69756 67.8227 9.69756C68.2317 9.69756 68.5517 9.50716 68.7828 9.12536C68.9821 8.80936 69.0803 8.43454 69.0803 8.0009Z" fill="white" />
            <path d="M75.3801 10.3555H74.418L74.3383 9.81419H74.3086C73.9794 10.2458 73.5101 10.4621 72.9007 10.4621C72.4458 10.4621 72.0777 10.3196 71.8006 10.0365C71.5491 9.7793 71.4233 9.45931 71.4233 9.0795C71.4233 8.50531 71.6687 8.06769 72.1625 7.76465C72.6554 7.4616 73.3486 7.31307 74.2411 7.32005V7.23232C74.2411 6.61328 73.9078 6.30425 73.2402 6.30425C72.7648 6.30425 72.3456 6.42088 71.9836 6.65215L71.7658 5.96631C72.2137 5.69617 72.7668 5.56059 73.4191 5.56059C74.6787 5.56059 75.3106 6.20855 75.3106 7.50447V9.23501C75.3106 9.70453 75.3341 10.0784 75.3801 10.3555ZM74.2677 8.74057V8.01586C73.0858 7.99592 72.4948 8.31192 72.4948 8.96287C72.4948 9.2081 72.5623 9.39152 72.7003 9.51413C72.8384 9.63675 73.0142 9.69756 73.2238 9.69756C73.459 9.69756 73.6788 9.62479 73.8792 9.48024C74.0806 9.3347 74.2043 9.15028 74.2503 8.92399C74.2616 8.87315 74.2677 8.81135 74.2677 8.74057Z" fill="white" />
            <path d="M81.4651 10.355H80.5143L80.4642 9.60042H80.4345C80.1309 10.1746 79.6135 10.4617 78.8866 10.4617C78.3059 10.4617 77.8223 10.2394 77.4389 9.79481C77.0554 9.35021 76.8643 8.77303 76.8643 8.06427C76.8643 7.30366 77.0718 6.6876 77.489 6.21709C77.8928 5.77847 78.3877 5.55916 78.9766 5.55916C79.6238 5.55916 80.0767 5.77149 80.3343 6.19715H80.3548V3.49965H81.4273V9.08904C81.4273 9.54659 81.4396 9.96827 81.4651 10.355ZM80.3548 8.37329V7.58976C80.3548 7.45419 80.3446 7.34453 80.3251 7.2608C80.2648 7.00959 80.135 6.79826 79.9376 6.62779C79.7383 6.45733 79.498 6.3716 79.2209 6.3716C78.8212 6.3716 78.5083 6.52611 78.2783 6.83614C78.0503 7.14616 77.9347 7.54191 77.9347 8.02539C77.9347 8.48992 78.0441 8.86674 78.2639 9.15682C78.496 9.46585 78.8089 9.62036 79.2005 9.62036C79.5522 9.62036 79.8334 9.49177 80.047 9.23358C80.2536 8.99533 80.3548 8.70824 80.3548 8.37329Z" fill="white" />
            <path d="M90.6292 7.96701C90.6292 8.68973 90.4176 9.28187 89.9943 9.7464C89.5505 10.2239 88.9637 10.4621 88.2286 10.4621C87.5221 10.4621 86.9587 10.2339 86.5375 9.77531C86.1183 9.31775 85.9087 8.74057 85.9087 8.04476C85.9087 7.31706 86.1244 6.71994 86.5579 6.2564C86.9914 5.79286 87.5742 5.56059 88.3093 5.56059C89.0158 5.56059 89.5853 5.78887 90.0147 6.24643C90.4237 6.69103 90.6292 7.26522 90.6292 7.96701ZM89.5189 8.0009C89.5189 7.56727 89.4227 7.19544 89.2316 6.88542C89.0056 6.5106 88.6866 6.32319 88.2695 6.32319C87.84 6.32319 87.5129 6.5106 87.2869 6.88542C87.0947 7.19544 86.9996 7.57325 86.9996 8.01984C86.9996 8.45348 87.0957 8.8253 87.2869 9.13533C87.519 9.51015 87.8421 9.69756 88.2592 9.69756C88.6682 9.69756 88.9903 9.50716 89.2213 9.12536C89.4187 8.80936 89.5189 8.43454 89.5189 8.0009Z" fill="white" />
            <path d="M96.3911 10.3559H95.3206V7.66433C95.3206 6.83494 94.9975 6.42025 94.3483 6.42025C94.0303 6.42025 93.7737 6.53389 93.5753 6.76217C93.377 6.99045 93.2778 7.25961 93.2778 7.56764V10.3549H92.2053V6.99943C92.2053 6.58673 92.193 6.13914 92.1675 5.65466H93.1081L93.1582 6.38935H93.1879C93.3136 6.16107 93.4997 5.97266 93.743 5.82214C94.0344 5.64669 94.3585 5.55797 94.7153 5.55797C95.1642 5.55797 95.5384 5.69952 95.8359 5.98363C96.207 6.33153 96.3911 6.85089 96.3911 7.54072V10.3559V10.3559Z" fill="white" />
            <path d="M103.605 6.43797H102.425V8.72078C102.425 9.30095 102.635 9.59103 103.05 9.59103C103.242 9.59103 103.402 9.57508 103.528 9.54219L103.555 10.3347C103.344 10.4124 103.065 10.4513 102.723 10.4513C102.3 10.4513 101.97 10.3257 101.732 10.0745C101.493 9.8233 101.374 9.40063 101.374 8.8075V6.43797H100.67V5.65543H101.374V4.79415L102.424 4.48512V5.65444H103.604V6.43797H103.605Z" fill="white" />
            <path d="M109.276 10.355H108.204V7.68347C108.204 6.84112 107.881 6.41945 107.233 6.41945C106.736 6.41945 106.397 6.66368 106.211 7.15214C106.179 7.25482 106.161 7.38042 106.161 7.52796V10.3541H105.09V3.49965H106.161V6.33173H106.181C106.519 5.81635 107.002 5.55916 107.629 5.55916C108.073 5.55916 108.44 5.70071 108.731 5.98482C109.094 6.3387 109.276 6.86504 109.276 7.56085V10.355V10.355Z" fill="white" />
            <path d="M115.127 7.78295C115.127 7.97036 115.113 8.12786 115.087 8.25645H111.874C111.888 8.72099 112.042 9.07488 112.339 9.3201C112.611 9.53941 112.962 9.64907 113.391 9.64907C113.867 9.64907 114.3 9.5753 114.691 9.42677L114.858 10.1525C114.401 10.3459 113.864 10.4426 113.241 10.4426C112.495 10.4426 111.907 10.2282 111.481 9.79959C111.054 9.37094 110.842 8.79576 110.842 8.07403C110.842 7.36526 111.04 6.77512 111.437 6.3046C111.852 5.80219 112.414 5.55098 113.122 5.55098C113.815 5.55098 114.342 5.80219 114.698 6.3046C114.985 6.70335 115.127 7.19679 115.127 7.78295ZM114.105 7.5128C114.113 7.20277 114.042 6.93562 113.897 6.71033C113.711 6.42024 113.428 6.2747 113.045 6.2747C112.695 6.2747 112.41 6.41625 112.192 6.70036C112.014 6.92664 111.909 7.19679 111.874 7.5128H114.105Z" fill="white" />
        </svg>

    )
}

export default AppStoreIcon
