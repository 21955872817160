import { useState, useMemo, useEffect } from "react"
import style from "./research.module.css"
import Upload from '../../icons/Upload';
import { getResearches } from '../../services/ratingData'
import {Link} from "react-router-dom";

export default function ResearchBlock() {
    const [data, setData] = useState([])

    const getData = async () => {
        try {
            const { data: res } = await getResearches();
            setData(res);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getData();
    }, [])
    return (


        <div className={style['research-block']}>
            <div className="container">
                <div className={style['research-block__wrap']}>

                    <div className={style['research-block__item']}>
                        <div className={style['research-block__description']}>
                            Логистические платформы — маркетплейсы как инструмент для организации грузоперевозок
                        </div>
                        <Link to="/research/logistics-platforms" className={style['download__btn__red']}>
                            <span>Читать</span>
                        </Link>
                    </div>

                    {
                        data.length ?
                            data.map(el => <div className={style['research-block__item']} key={el.id}>
                            <div className={style['research-block__description']}>
                                    {el.description}
                                    {/* Реестр крупнейших грузовладельцев, вовлеченных в компрометирующие схемы, связанные с получением необоснованной налоговой выгоды */}
                                </div>
                                <a className={style['download__btn']} href={el.file} target='_blank'>
                                    <span>Скачать файл</span>
                                    <Upload/>
                                </a>
                            </div>) : <div className='rating-table__search'>
                                <div className='rating-table__notification'>
                                    Публикация запланирована на декабрь 2023 года
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
